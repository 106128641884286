import { FC } from "react";

import { TItem } from "../../types/TItem";

import BranchItemTable from "../../components/BranchItemTable";

interface props {
  setShow: Function;
  setNotificationTitle: Function;
  items: TItem[];
  getAllItems: Function;
  onEdit: Function;
}

const ItemTable: FC<props> = ({
  setShow,
  setNotificationTitle,
  items,
  getAllItems,
  onEdit,
}) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <BranchItemTable
              data={items}
              setShow={setShow}
              setNotificationTitle={setNotificationTitle}
              refetchData={getAllItems}
              onEdit={onEdit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemTable;
