import React from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { PlusSmIcon } from "@heroicons/react/solid";
import { DocumentAddIcon } from "@heroicons/react/outline";
import moment from "moment";

import Table from "./Table";

import CircleButton from "../../components/CircleButton";
import Notification from "../../components/Notification";
import Modal from "../../components/Modal";
import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";

import { TBranch } from "../../types/TBranch";
import { TReport } from "../../types/TReport";

import services from "../../services";

import CreateBatchReport from "./CreateBatchReport";

import { useCustomContext } from "../../hooks/useCustomContext";

const Report = () => {
  const context = useCustomContext();
  const navigate = useNavigate();

  const defaultBranchId = context.setting?.defaultBranchId;

  const [search, setSearch] = useSearchParams();

  const currentMonth = search.get("month");
  const currentBranch = search.get("branch");

  const [show, setShow] = React.useState<boolean>(false);
  const [month, setMonth] = React.useState<string>(
    moment(new Date()).format("YYYY-MM")
  );
  const [showBatchCreate, setShowBatchCreate] = React.useState<boolean>(false);
  const [notificationTitle, setNotificationTitle] = React.useState<string>("");
  const [branches, setBranches] = React.useState<TBranch[]>([]);
  const [reports, setReports] = React.useState<TReport[]>([]);
  const [branchId, setBranchId] = React.useState<string>("");

  const getAllBranches = async () => {
    const data = await services.branch.getBranches();

    if (data.ok === true) {
      setBranches(data.data);
    }
  };

  const lastday = (y: number, m: number) => {
    return new Date(y, m, 0).getDate();
  };

  const getAllReports = async (id: string, currentMonth: any) => {
    const splitMonth = currentMonth?.split("-");
    const year = Number(splitMonth?.[0]);
    const month = Number(splitMonth?.[1]);
    const startDate = `${currentMonth}-01`;
    const endDate = `${currentMonth}-${lastday(year, month)}`;

    const start = moment(new Date(startDate)).startOf("day").unix();
    const end = moment(new Date(endDate)).endOf("day").unix();

    const data = await services.report.getReportsByBranchId(id, start, end);

    if (data.ok === true) {
      setReports(data.data);
    }
  };

  const onEdit = (id: string) => {
    navigate(
      `/report/form?mode=edit&id=${id}&month=${month}&branch=${branchId}`
    );
  };

  React.useEffect(() => {
    if (currentMonth) setMonth(currentMonth);
    if (currentBranch) setBranchId(currentBranch);

    if (!branchId) {
      if (!currentBranch) {
        setBranchId(defaultBranchId || branches?.[0]?.id!);
      }
    }
  }, [
    search,
    branches,
    branchId,
    currentMonth,
    currentBranch,
    defaultBranchId,
  ]);

  React.useEffect(() => {
    getAllBranches();
    getAllReports(branchId, month);
  }, [showBatchCreate, branchId, month]);

  const onSetQuery = (value: string, key: string) => {
    const query: any = {};
    if (currentBranch) query.branch = currentBranch;
    if (currentMonth) query.month = currentMonth;

    query[key] = value;

    setSearch(query);
  };

  const branchOptions = branches.map((el) => ({ id: el.id, label: el.name }));

  return (
    <div>
      <Notification show={show} setShow={setShow} title={notificationTitle} />
      <Modal
        isOpen={showBatchCreate}
        setIsOpen={setShowBatchCreate}
        title="Upload File to Batch Create"
      >
        <CreateBatchReport setShowBatchCreate={setShowBatchCreate} />
      </Modal>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex justify-between">
        <div className="flex gap-x-2 mr-2 border border-transparent">
          <h1 className="text-2xl font-semibold text-gray-900">Laporan</h1>
          <NavLink to="/report/form?mode=create">
            <CircleButton
              icon={<PlusSmIcon className="h-5 w-5" aria-hidden="true" />}
            />
          </NavLink>
          <div>
            <CircleButton
              onClick={() => setShowBatchCreate(true)}
              icon={<DocumentAddIcon className="h-5 w-5" aria-hidden="true" />}
            />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-x-2">
          <TextInput
            type="month"
            id="month"
            value={month}
            setValue={(value: string) => {
              setMonth(value);
              onSetQuery(value, "month");
            }}
          />
          <SelectInput
            id="branchId"
            value={branchId}
            setValue={(value: string) => {
              setBranchId(value);
              onSetQuery(value, "branch");
            }}
            values={branchOptions}
            placeholder="Cabang"
          />
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <Table
            setShow={setShow}
            setNotificationTitle={setNotificationTitle}
            reports={reports}
            getAllReports={getAllReports}
            onEdit={onEdit}
            branches={branches}
            branchId={branchId}
            month={month}
          />
        </div>
      </div>
    </div>
  );
};

export default Report;
