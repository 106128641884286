import React, { FC } from "react";

import ReportTable from "../../components/ReportTable";

import { TReport } from "../../types/TReport";
import { TBranch } from "../../types/TBranch";

interface props {
  setShow: Function;
  setNotificationTitle: Function;
  reports: TReport[];
  getAllReports: Function;
  onEdit: Function;
  branches: TBranch[];
  branchId: string;
  month: string;
}

const Table: FC<props> = ({
  setShow,
  setNotificationTitle,
  reports,
  getAllReports,
  onEdit,
  branches,
  branchId,
  month,
}) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <ReportTable
              data={reports}
              setShow={setShow}
              setNotificationTitle={setNotificationTitle}
              refetchData={getAllReports}
              onEdit={onEdit}
              branches={branches}
              branchId={branchId}
              month={month}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
