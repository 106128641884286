import React from "react";
import { Navigate } from "react-router-dom";

import useAuthentication from "../hooks/useAuthentication";

const Protected: React.FC = ({ children }) => {
  const isLoggedIn = useAuthentication();

  if (isLoggedIn === undefined) return <p className="py-2 sm:px-6 lg:px-8">Please wait...</p>;
  if (isLoggedIn === false) return <Navigate to="/login" />;

  return <>{children}</>;
};

export default Protected;
