import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const CustomChart = ({ data, type }) => {
  return (
    <div>
      <Bar
        data={{
          datasets: [
            {
              label: `Plan${type === "production" ? " (Kg/Hari)" : ""}`,
              data: data.data.map((el) => el[type].plan),
              backgroundColor: "skyblue",
              order: 1,
              barPercentage: 0.7,
            },
            {
              label: `Actual${type === "production" ? " (Kg/Hari)" : ""}`,
              data: data.data.map((el) => el[type].actual),
              backgroundColor: "lime",
              order: 2,
              barPercentage: 0.7,
            },
            {
              label: "Capaian %",
              type: "line",
              data: data.data.map((el) =>
                Number(((el[type].actual / el[type].plan) * 100).toFixed(0))
              ),
              backgroundColor: "cyan",
              order: 3,
              barPercentage: 0.7,
            },
          ],
          labels: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DES",
          ],
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 50,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default CustomChart;
