import { FC } from "react";

import { TPlan } from "../../types/TPlan";

import BranchPlanTable from "../../components/BranchPlanTable";

interface props {
  setShow: Function;
  setNotificationTitle: Function;
  plans: TPlan[];
  getAllPlans: Function;
  onEdit: Function;
}

const PlanTable: FC<props> = ({
  setShow,
  setNotificationTitle,
  plans,
  getAllPlans,
  onEdit,
}) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <BranchPlanTable
              data={plans}
              setShow={setShow}
              setNotificationTitle={setNotificationTitle}
              refetchData={getAllPlans}
              onEdit={onEdit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanTable;
