import { FC } from "react";
import classNames from "classnames";

interface props {
  id: string;
  placeholder: string;
  label?: string;
  value: any;
  setValue: Function;
  values: TValue[];
  isError?: boolean;
  errorMessage?: string;
  required?: boolean;
}

type TValue = {
  id: any;
  label: string;
};

const SelectInput: FC<props> = ({
  id,
  placeholder,
  label,
  value,
  setValue,
  values,
  isError,
  errorMessage,
  required = false,
}) => {
  const primaryColor =
    "border-gray-300 text-gray-900 placeholder-gray-300 focus:ring-indigo-500 focus:border-indigo-500";
  const errorColor =
    "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500";

  if (!required) {
    return (
      <div>
        {label && (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        <select
          id={id}
          name={id}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={classNames(
            primaryColor,
            "mt-1 block w-full pl-3 pr-10 py-2 sm:text-sm rounded-md"
          )}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {values?.map((el, i) => (
            <option key={i} value={el.id}>
              {el.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <select
        id={id}
        name={id}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={classNames(
          isError && !value ? errorColor : primaryColor,
          "mt-1 block w-full pl-3 pr-10 py-2 sm:text-sm rounded-md"
        )}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {values?.map((el, i) => (
          <option key={i} value={el.id}>
            {el.label}
          </option>
        ))}
      </select>
      {isError && !value && (
        <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
      )}
    </div>
  );
};

export default SelectInput;
