import React from "react";
import moment from "moment";

import SelectInput from "../../components/SelectInput";
import Button from "../../components/Button";
import Notification from "../../components/Notification";

import { useCustomContext } from "../../hooks/useCustomContext";

import services from "../../services";

import { TBranch } from "../../types/TBranch";
import { TSetting } from "../../types/TSetting";


const Settings = () => {
  const context = useCustomContext();
  const setting = context.setting;

  const userId = localStorage.getItem("userId");

  const [show, setShow] = React.useState<boolean>(false);

  const [branches, setBranches] = React.useState<TBranch[]>([]);
  const [branchId, setBranchId] = React.useState<string>("");

  React.useEffect(() => {
    getAllBranches();
  }, []);

  React.useEffect(() => {
    if (setting) {
      setBranchId(setting.defaultBranchId);
    }
  }, [setting]);

  const getAllBranches = async () => {
    const data = await services.branch.getBranches();

    if (data.ok === true) {
      setBranches(data.data);
    }
  };

  const onSaveSettings = async () => {
    const payload: TSetting = {
      defaultBranchId: branchId,
      updatedAt: moment().unix(),
      createdAt: setting?.createdAt || moment().unix(),
    };

    if (setting?.id) {
      await services.setting.updateSetting(String(setting?.id), payload);
      setShow(true);
      context.getSetting();
    } else {
      await services.setting.setSetting(String(userId), payload);
      setShow(true);
      context.getSetting();
    }
  };

  const branchOptions = branches.map((el) => ({ id: el.id, label: el.name }));

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <Notification
        title="Berhasil Menyimpan Pengaturan"
        show={show}
        setShow={setShow}
      />
      <div>
        <div className="mt-3">
          <SelectInput
            id="branchId"
            label="Default Cabang"
            value={branchId}
            setValue={setBranchId}
            values={branchOptions}
            placeholder="Pilih cabang"
          />
        </div>
        <div className="mt-3 text-right">
          <Button label="Simpan Perubahan" onClick={onSaveSettings} />
        </div>
      </div>
    </div>
  );
};

export default Settings;
