import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";

import { firestore } from "../utils/firebase";
import { success, fail, notFound } from "../utils/status.message";

import { TManager, tManagerConverter } from "../types/TManager";

const managerRef = collection(firestore, "managers");

const getManagers = async () => {
  try {
    const querySnapshot = await getDocs(
      managerRef.withConverter(tManagerConverter)
    );
    const data = querySnapshot.docs.map((el) => el.data());

    return success("Berhasil mendapatkan semua data PJ", data);
  } catch (error) {
    return fail();
  }
};

const getManager = async (id: string) => {
  try {
    const docRef = doc(managerRef, id);
    const docSnap = await getDoc(docRef.withConverter(tManagerConverter));

    if (docSnap.exists()) {
      return success("Berhasil mendapatkan data PJ", docSnap.data());
    } else {
      return notFound();
    }
  } catch (error) {
    return fail();
  }
};

const createManager = async (manager: TManager) => {
  try {
    const docRef = doc(managerRef);
    await setDoc(docRef, manager);

    return success("Berhasil membuat PJ", manager);
  } catch (error) {
    return fail();
  }
};

const updateManager = async (id: string, manager: TManager) => {
  try {
    const docRef = doc(firestore, "managers", id);
    await updateDoc(docRef, manager);

    return success("Berhasil memperbarui PJ", manager);
  } catch (error) {
    return fail();
  }
};

const deleteManager = async (id: string) => {
  try {
    const docRef = doc(firestore, "managers", id);
    await deleteDoc(docRef);

    return success("Berhasil menghapus PJ", null);
  } catch (error) {
    return fail();
  }
};

const manager = {
  getManagers,
  getManager,
  createManager,
  updateManager,
  deleteManager,
};

export default manager;
