import { FC } from "react";
import classNames from "classnames";
import NumberFormat from "react-number-format";

interface props {
  id: string;
  placeholder?: string;
  label?: string;
  value: string;
  setValue: Function;
  isError?: boolean;
  errorMessage?: string;
  required?: boolean;
  rounded?: string;
}

const NumberInput: FC<props> = ({
  id,
  placeholder,
  label,
  value,
  setValue,
  isError,
  errorMessage,
  required = false,
  rounded = "rounded-md",
}) => {
  const primaryColor =
    "border-gray-300 text-gray-900 placeholder-gray-300 focus:ring-indigo-500 focus:border-indigo-500";
  const errorColor =
    "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500";

  if (!required) {
    return (
      <div>
        {label && (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
        )}
        <div className={classNames(rounded, "mt-1 relative shadow-sm")}>
          <NumberFormat
            thousandSeparator="."
            decimalSeparator=","
            id={id}
            name={id}
            placeholder={placeholder}
            value={value}
            onValueChange={({ value }) => setValue(value)}
            className={classNames(
              primaryColor,
              rounded,
              "block w-full focus:outline-none sm:text-sm"
            )}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className={classNames(rounded, "mt-1 relative shadow-sm")}>
        <NumberFormat
          thousandSeparator="."
          decimalSeparator=","
          id={id}
          name={id}
          placeholder={placeholder}
          value={value}
          onValueChange={({ value }) => setValue(value)}
          className={classNames(
            isError && !value ? errorColor : primaryColor,
            rounded,
            "block w-full focus:outline-none sm:text-sm"
          )}
        />
      </div>
      {isError && !value && (
        <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
      )}
    </div>
  );
};

export default NumberInput;
