import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  QueryConstraint,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";

import { firestore } from "../utils/firebase";
import { success, fail, notFound } from "../utils/status.message";

import { TReport, tReportConverter } from "../types/TReport";

const reportRef = collection(firestore, "reports");

const getReports = async () => {
  try {
    const querySnapshot = await getDocs(
      query(reportRef.withConverter(tReportConverter), orderBy("date", "asc"))
    );
    const data = querySnapshot.docs.map((el) => el.data());

    return success("Berhasil mendapatkan semua data Laporan", data);
  } catch (error) {
    return fail();
  }
};

const getReportsByDate = async (startDate: any, endDate: any) => {
  try {
    const constraints: QueryConstraint[] = [];

    if (startDate) constraints.push(where("date", ">=", startDate));
    if (endDate) constraints.push(where("date", "<=", endDate));

    const querySnapshot = await getDocs(
      query(
        reportRef.withConverter(tReportConverter),
        ...constraints,
        orderBy("date", "asc")
      )
    );
    const data = querySnapshot.docs.map((el) => el.data());

    return success("Berhasil mendapatkan semua data report", data);
  } catch (error) {
    return fail();
  }
};

const getReportsByBranchId = async (
  id: string,
  startDate?: any,
  endDate?: any
) => {
  try {
    const constraints: QueryConstraint[] = [];

    if (id) constraints.push(where("BranchId", "==", id));
    if (startDate) constraints.push(where("date", ">=", startDate));
    if (endDate) constraints.push(where("date", "<=", endDate));

    const querySnapshot = await getDocs(
      query(
        reportRef.withConverter(tReportConverter),
        ...constraints,
        orderBy("date", "asc")
      )
    );
    const data = querySnapshot.docs.map((el) => el.data());

    return success("Berhasil mendapatkan semua data report", data);
  } catch (error) {
    return fail();
  }
};

const getReport = async (id: string) => {
  try {
    const docRef = doc(reportRef, id);
    const docSnap = await getDoc(docRef.withConverter(tReportConverter));

    if (docSnap.exists()) {
      return success("Berhasil mendapatkan data Laporan", docSnap.data());
    } else {
      return notFound();
    }
  } catch (error) {
    return fail();
  }
};

const createReport = async (report: TReport) => {
  try {
    const docRef = doc(reportRef);
    await setDoc(docRef, report);

    return success("Berhasil membuat Laporan", report);
  } catch (error) {
    return fail();
  }
};

const createBatchReport = async (reports: TReport[]) => {
  try {
    const batch = writeBatch(firestore);

    reports.forEach(async (report) => {
      const docRef = doc(reportRef);
      await batch.set(docRef, report);
    });

    await batch.commit();

    return success("Berhasil membuat Laporan", reports);
  } catch (error) {
    return fail();
  }
};

const updateReport = async (id: string, report: TReport) => {
  try {
    const docRef = doc(firestore, "reports", id);
    await updateDoc(docRef, report);

    return success("Berhasil memperbarui Laporan", report);
  } catch (error) {
    return fail();
  }
};

const deleteReport = async (id: string) => {
  try {
    const docRef = doc(firestore, "reports", id);
    await deleteDoc(docRef);

    return success("Berhasil menghapus Laporan", null);
  } catch (error) {
    return fail();
  }
};

const report = {
  getReports,
  getReportsByDate,
  getReportsByBranchId,
  getReport,
  createReport,
  createBatchReport,
  updateReport,
  deleteReport,
};

export default report;
