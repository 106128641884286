import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

import { firestore } from "../utils/firebase";
import { success, fail } from "../utils/status.message";

import { TSetting, tSettingConverter } from "../types/TSetting";

const settingRef = collection(firestore, "settings");

const getSettingByUserId = async (id: string) => {
  try {
    const docRef = doc(firestore, "settings", id).withConverter(
      tSettingConverter
    );
    const querySnapshot = await getDoc(docRef);

    const data = querySnapshot.data();

    return success("Berhasil mendapatkan data pengaturan", data);
  } catch (error) {
    return fail();
  }
};

const updateSetting = async (id: string, item: TSetting) => {
  try {
    const docRef = doc(firestore, "settings", id);
    await updateDoc(docRef, item);

    return success("Berhasil memperbarui pengaturan", item);
  } catch (error) {
    return fail();
  }
};

const setSetting = async (id: string, item: TSetting) => {
  try {
    const docRef = doc(settingRef, id);
    await setDoc(docRef, item);

    return success("Berhasil membuat pengaturan", item);
  } catch (error) {
    return fail();
  }
};

const plan = {
  getSettingByUserId,
  updateSetting,
  setSetting,
};

export default plan;
