import { FC, useEffect, useState } from "react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import clearState from "../../../utils/clear.state";

import { TBopItem } from "../../../types/TBopItem";

import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import CheckboxInput from "../../../components/CheckboxInput";
import NumberInput from "../../../components/NumberInput";

import services from "../../../services";

interface props {
  isEdit: boolean;
  editState: TBopItem | null;
  isOpen: boolean;
  setIsOpen: Function;
  setShow: Function;
  setNotificationTitle: Function;
  getAllBopItems: Function;
}

const BranchBopItemForm: FC<props> = ({
  isOpen,
  setIsOpen,
  isEdit,
  editState,
  setShow,
  setNotificationTitle,
  getAllBopItems,
}) => {
  const [search] = useSearchParams();

  const branchId = search.get("id") as string;

  const [isError, setIsError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [isDepositComponent, setIsDepositComponent] = useState<boolean>(false);
  const [isEducationBudget, setIsEducationBudget] = useState<boolean>(false);
  const [number, setNumber] = useState<number>(0);
  const [defaultAmount, setDefaultAmount] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const validate = () => {
    if (name) {
      return true;
    } else {
      setIsError(true);
      return false;
    }
  };

  const clearCurrentState = () => {
    const setStates = [setName, setDescription, setDefaultAmount];
    setIsDepositComponent(false);
    setIsEducationBudget(false);
    setNumber(0);
    setIsError(false);

    clearState(setStates);
  };

  const setDefaultState = (state: TBopItem | null) => {
    if (state) {
      setName(state.name);
      setIsDepositComponent(state.isDepositComponent);
      setIsEducationBudget(state.isEducationBudget);
      setDefaultAmount(state.defaultAmount);
      setDescription(state.description);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setDefaultState(editState);
    }
  }, [editState, isEdit]);

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    setLoading(true);
    const isValid = validate();

    if (isValid) {
      const payload: TBopItem = {
        name,
        BranchId: branchId,
        isDepositComponent,
        isEducationBudget,
        number,
        defaultAmount,
        description: description,
        createdAt: editState ? editState?.createdAt : moment().unix(),
        updatedAt: moment().unix(),
      };

      let item;

      if (isEdit) {
        item = await services.bopItem.updateBopItem(editState?.id!, payload);
      } else {
        item = await services.bopItem.createBopItem(payload);
      }

      if (item.ok) {
        setShow(true);
        setIsOpen(false);
        setNotificationTitle(item.message);
        clearCurrentState();
        setLoading(false);
        getAllBopItems(branchId);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const onCloseModal = (value: boolean) => {
    setIsOpen(value);
    clearCurrentState();
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={onCloseModal}
      title={`${isEdit ? "Edit" : "Tambah"} Bop Item Cabang`}
    >
      <form className="flex flex-col gap-y-3 mt-5">
        <TextInput
          id="name"
          label="Nama"
          type="text"
          value={name}
          setValue={setName}
          isError={isError}
          errorMessage="Nama wajib diisi"
          required={true}
        />

        <NumberInput
          id="defaultAmount"
          label="Default harga (Rp)"
          value={defaultAmount}
          setValue={setDefaultAmount}
        />

        <TextInput
          id="description"
          label="Deskripsi"
          type="text"
          value={description}
          setValue={setDescription}
        />

        <CheckboxInput
          label="Komponen setoran"
          id="isDepositComponent"
          value={isDepositComponent}
          setValue={setIsDepositComponent}
        />

        <CheckboxInput
          label="Komponen dana pendidikan"
          id="isEducationBudget"
          value={isEducationBudget}
          setValue={setIsEducationBudget}
        />

        <div className="text-right">
          <Button
            disabled={loading}
            loading={loading ? "true" : "false"}
            onClick={onSubmit}
            label={isEdit ? "Edit" : "Tambahkan"}
            type="submit"
          />
        </div>
      </form>
    </Modal>
  );
};

export default BranchBopItemForm;
