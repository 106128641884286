import { DocumentSnapshot } from "./TFirebase";

export type TSetting = {
  id?: string;
  defaultBranchId: string;
  createdAt: number;
  updatedAt: number;
};

export const tSettingConverter = {
  fromFirestore: (snapshot: DocumentSnapshot): TSetting => {
    return { ...snapshot.data(), id: snapshot.id } as unknown as TSetting;
  },
  toFirestore: (report: TSetting) => {
    return report;
  },
};
