import { onAuthStateChanged } from "firebase/auth";

import { useEffect, useState } from "react";
import { auth } from "../utils/firebase";

const useAuthentication = () => {
  const [isLoggedIn, setIsLogedIn] = useState<boolean>();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        if (user) {
          setIsLogedIn(true);
          localStorage.setItem("userId", user.uid);
        } else {
          setIsLogedIn(false);
          localStorage.removeItem("userId");
        }
      },
      () => {
        setIsLogedIn(false);
        localStorage.removeItem("userId");
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return isLoggedIn;
};

export default useAuthentication;
