import React from "react";
import moment from "moment";

import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";

import { TReport } from "../../types/TReport";
import { TBranch } from "../../types/TBranch";

import services from "../../services";

import MultipleValue from "./MultipleValue";
import OneValue from "./OneValue";

import { currency } from "../../utils/currency";

import { useCustomContext } from "../../hooks/useCustomContext";

const ProfitAndLoss = () => {
  const context = useCustomContext();

  const defaultBranchId = context.setting?.defaultBranchId;

  const [branchId, setBranchId] = React.useState<string>("");
  const [startDate, setStartDate] = React.useState<string>("");
  const [endDate, setEndDate] = React.useState<string>("");

  const [branches, setBranches] = React.useState<TBranch[]>([]);
  const [reports, setReports] = React.useState<TReport[]>([]);

  const getAllBranches = async () => {
    const data = await services.branch.getBranches();

    if (data.ok === true) {
      setBranches(data.data);
    }
  };

  const getAllReports = async (id: string, startDate: any, endDate: any) => {
    const start = moment(new Date(startDate)).startOf("day").unix();
    const end = moment(new Date(endDate)).endOf("day").unix();

    const data = await services.report.getReportsByBranchId(id, start, end);

    if (data.ok === true) {
      setReports(data.data);
    }
  };

  const month = moment(new Date()).format("YYYY-MM");

  React.useEffect(() => {
    if (!startDate) {
      setStartDate(`${month}-01`);
    }
  }, [month, startDate]);

  React.useEffect(() => {
    getAllBranches();
    getAllReports(branchId, startDate, endDate);
  }, [branchId, startDate, endDate]);

  React.useEffect(() => {
    if (!branchId) {
      setBranchId(defaultBranchId || branches?.[0]?.id!);
    }
  }, [branches, branchId, defaultBranchId]);

  const sumTempehValues = (data: any[]) => {
    const total = data
      ?.map((el) => Number(el.value) * (Number(el.other) / 1000))
      ?.reduce((a, b) => a + b, 0);

    return total;
  };

  const sumHppItemValues = (data: any[], totalProduction: any) => {
    const total = data
      ?.map((el, i) => {
        if (el.name === "Biaya Kedelai") {
          return Number(totalProduction) * Number(data[i + 1].value);
        }
        if (i === 1) {
          return 0;
        } else {
          return Number(el.value);
        }
      })
      ?.reduce((a, b) => a + b, 0);

    return total;
  };

  const sumBopValues = (data: any[]) => {
    const total = data
      ?.map((el) => Number(el.value))
      ?.reduce((a, b) => a + b, 0);

    return total;
  };

  const uptrendSoybeanVolum = (data: any[], totalProduction: any) => {
    const total = (sumTempehValues(data) / Number(totalProduction)) * 100 - 100;

    return total || 0;
  };

  const branchOptions = branches.map((el) => ({ id: el.id, label: el.name }));

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col sm:flex-row sm:justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Laba / Rugi</h1>
        <div className="flex flex-col sm:flex-row gap-x-2">
          <TextInput
            type="date"
            id="startDate"
            value={startDate}
            setValue={setStartDate}
          />
          <TextInput
            type="date"
            id="endDate"
            value={endDate}
            setValue={setEndDate}
          />
          <SelectInput
            id="branchId"
            value={branchId}
            setValue={setBranchId}
            values={branchOptions}
            placeholder="Cabang"
          />
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        {/* Replace with your content */}
        <div className="py-4 grid grid-cols-1 md:grid-cols-2 gap-3">
          {reports?.map((el) => (
            <div
              key={el.id}
              className="border border-gray-900 rounded-lg divide-y divide-gray-900"
            >
              <OneValue
                label="Tanggal"
                value={moment.unix(el.date).format("DD MMMM YYYY")}
                background="bg-indigo-400 rounded-t-lg font-bold"
              />
              <OneValue
                label="PRODUKSI (KG)"
                value={currency(el.totalProduction)}
              />
              <OneValue
                label="Volume Produksi (Kg)"
                value={currency(sumTempehValues(el.productionItems).toFixed(0))}
                className="text-xs"
              />
              <MultipleValue
                label="PRODUKSI (BTG)"
                values={el.productionItems}
              />
              <OneValue
                label="%kenaikan volume kedelai"
                value={`${uptrendSoybeanVolum(
                  el.productionItems,
                  el.totalProduction
                ).toFixed(0)}%`}
                className="text-xs"
              />
              <MultipleValue
                label="TERSIMPAN SEBELUMNYA"
                values={el.previouslySavedItems}
              />
              <OneValue label="PENJUALAN" value="" />
              <MultipleValue label="Terjual" values={el.soldItems} />
              <MultipleValue label="Piutang" values={el.receivableItems} />
              <MultipleValue label="BS" values={el.returnItems} />
              <MultipleValue label="Afkir" values={el.writeOffItems} />
              <OneValue
                label="Pendapatan Lain"
                value={el.otherIncome ? `Rp${currency(el.otherIncome)}` : "-"}
                className="text-xs"
              />
              <OneValue
                label="Realisasi Pendapatan"
                value={`Rp${currency(el.revenueRealization)}`}
                className="text-xs"
              />
              <OneValue
                label="Pendapatan Total"
                value={`Rp${currency(
                  Number(el.otherIncome) + Number(el.revenueRealization)
                )}`}
              />
              <OneValue label="HPP" value="" />
              <MultipleValue
                values={el.hppItems.map((hpp, i) => {
                  if (hpp.name === "Biaya Kedelai") {
                    return {
                      ...hpp,
                      value:
                        Number(el.totalProduction) *
                        Number(el.hppItems[i + 1].value),
                    };
                  } else {
                    return hpp;
                  }
                })}
              />
              <OneValue
                label="TOTAL HPP"
                value={`Rp${currency(
                  sumHppItemValues(el.hppItems, el.totalProduction)
                )}`}
              />
              <OneValue
                label="LABA KOTOR"
                value={`Rp${currency(
                  Number(el.otherIncome) +
                    Number(el.revenueRealization) -
                    sumHppItemValues(el.hppItems, el.totalProduction)
                )}`}
              />
              <OneValue label="BIAYA OPERASIONAL" value="" />
              <MultipleValue values={el.bopItems} />
              <OneValue
                label="JUMLAH BOP"
                value={`Rp${currency(sumBopValues(el.bopItems))}`}
              />
              <OneValue
                label="LABA BERSIH"
                value={`Rp${currency(
                  Number(el.otherIncome) +
                    Number(el.revenueRealization) -
                    sumHppItemValues(el.hppItems, el.totalProduction) -
                    sumBopValues(el.bopItems)
                )}`}
              />
            </div>
          ))}
        </div>
        {/* /End replace */}
      </div>
    </div>
  );
};

export default ProfitAndLoss;
