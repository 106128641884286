export const generateReportTemplate = (
  branchId: string,
  listItem: string[][],
  listHppItem: string[][],
  listBopItem: string[][]
) => {
  const csvData = [
    ["branch", branchId],
    ["totalProduction"],
    ["date"],
    ["otherIncome"],
    ["revenueRealization"],
    ["production"],
    ...listItem,
    ["previouslySaved"],
    ...listItem,
    ["sold"],
    ...listItem,
    ["receivable"],
    ...listItem,
    ["return"],
    ...listItem,
    ["writeOff"],
    ...listItem,
    ["hpp"],
    ...listHppItem,
    ["bop"],
    ...listBopItem,
  ];

  return csvData;
};
