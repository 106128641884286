import { DocumentSnapshot } from "./TFirebase";

export type TBranch = {
  id?: string;
  name: string;
  region: string;
  province: string;
  city: string;
  district: string;
  subDistrict: string;
  address: string;
  phone: string;
  email: string | null;
  status: EBranchStatus;
  mapCoordinates: string | null;
  createdAt: number;
  updatedAt: number;
};

export enum EBranchStatus {
  active = "ACTIVE",
  inactive = "INACTIVE",
}

export const tBranchConverter = {
  fromFirestore: (snapshot: DocumentSnapshot): TBranch => {
    return { ...snapshot.data(), id: snapshot.id } as unknown as TBranch;
  },
  toFirestore: (branch: TBranch) => {
    return branch;
  },
};
