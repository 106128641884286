import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  BellIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  DocumentTextIcon,
  FolderAddIcon,
  LoginIcon,
  LogoutIcon,
  MenuAlt2Icon,
  UserCircleIcon,
  XIcon,
  CogIcon,
} from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { signOut } from "firebase/auth";

import { auth } from "../utils/firebase";

import Notification from "../components/Notification";
import Protected from "../components/Protected";

import Dashboard from "./Dashboard";
import Branch from "./Branch";
import BranchDetail from "./BranchDetail";
import BranchManager from "./BranchManager";
import Report from "./Report";
import ReportForm from "./ReportForm";
import Login from "./Login";
import ProfitAndLoss from "./ProfitAndLoss";

import { TItem } from "../types/TItem";

import services from "../services";

import useAuthentication from "../hooks/useAuthentication";
import { CustomContext } from "../hooks/useCustomContext";

import Recap from "./Recap";
import Settings from "./Settings";
import { TSetting } from "../types/TSetting";

export default function Pages() {
  const userId = localStorage.getItem("userId");

  const { pathname } = useLocation();

  const [search, setSearch] = useSearchParams();

  const [currentSearch, setCurrentSearch] = useState<string>("");

  const isHomepage = pathname === "/";
  const isProfitAndLoss = pathname === "/profit-loss";
  const isRecap = pathname === "/recap";
  const isLoginpage = pathname === "/login";
  const isBranchPage = pathname.includes("/branch");
  const isBranchDetail = pathname === "/branch/detail";
  const isReportPage = pathname.includes("/report");
  const isReportCreate = pathname === "/report/form";
  const isSettings = pathname === "/settings";

  const navigation = [
    { name: "Dashboard", to: "/", icon: ChartBarIcon, current: isHomepage },
    {
      name: "Rekap",
      to: "/recap",
      icon: CurrencyDollarIcon,
      current: isRecap,
    },
    {
      name: "Laba / Rugi",
      to: "/profit-loss",
      icon: CurrencyDollarIcon,
      current: isProfitAndLoss,
    },
    {
      name: "Cabang",
      to: "/branch",
      icon: FolderAddIcon,
      current: isBranchPage,
    },
    {
      name: "Laporan",
      to: "/report",
      icon: DocumentTextIcon,
      current: isReportPage,
    },
    {
      name: "Pengaturan",
      to: "/settings",
      icon: CogIcon,
      current: isSettings,
    },
  ];

  const isLoggedIn = useAuthentication();

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setShowNotificationMessage] =
    useState<string>("");

  const [items, setItems] = useState<TItem[]>([]);
  const [setting, setSetting] = useState<TSetting | null>(null);

  const getAllItems = async () => {
    const data = await services.item.getItems();

    if (data.ok) {
      setItems(data.data);
    }
  };

  const getAllSetting = async () => {
    const data = await services.setting.getSettingByUserId(String(userId));

    if (data.ok) {
      setSetting(data.data);
    }
  };

  const onSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (currentSearch) {
      setSearch({ search: currentSearch });
    } else {
      setSearch({});
    }
  };

  const onSignOutUser = () => {
    signOut(auth)
      .then(() => {
        setShowNotification(true);
        setShowNotificationMessage("Sign out success");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (!search.get("search")) {
      setCurrentSearch("");
    }
  }, [search]);

  useEffect(() => {
    if (
      !currentSearch &&
      !isHomepage &&
      !isReportPage &&
      !isReportCreate &&
      !isLoginpage &&
      !isProfitAndLoss &&
      !isRecap &&
      !isBranchDetail
    ) {
      setSearch({});
    }
  }, [
    currentSearch,
    isHomepage,
    isReportPage,
    isReportCreate,
    isLoginpage,
    isProfitAndLoss,
    isRecap,
    isBranchDetail,
  ]);

  useEffect(() => {
    if (isLoggedIn) {
      getAllItems();
      getAllSetting();
    }
  }, [isLoggedIn]);

  const store = {
    items,
    getItems: getAllItems,
    setting,
    getSetting: getAllSetting,
  };

  return (
    <CustomContext.Provider value={store}>
      <Notification
        show={showNotification}
        setShow={setShowNotification}
        title={notificationMessage}
      />
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"
                    alt="Workflow"
                  />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="space-y-1">
                    {isLoggedIn && (
                      <>
                        {navigation.map((item) => (
                          <div key={item.name} className="px-2">
                            <NavLink
                              to={item.to}
                              className={classNames(
                                item.current
                                  ? "bg-gray-900 text-white"
                                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-gray-300"
                                    : "text-gray-400 group-hover:text-gray-300",
                                  "mr-4 flex-shrink-0 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </NavLink>
                          </div>
                        ))}
                      </>
                    )}

                    {isLoggedIn ? (
                      <div className="absolute bottom-3 w-full px-2">
                        <div
                          onClick={onSignOutUser}
                          className={classNames(
                            isLoginpage
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer"
                          )}
                        >
                          <LogoutIcon
                            className={classNames(
                              isLoginpage
                                ? "text-gray-300"
                                : "text-gray-400 group-hover:text-gray-300",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          Sign out
                        </div>
                      </div>
                    ) : (
                      <div className="absolute bottom-3 w-full px-2">
                        <NavLink
                          to="/login"
                          className={classNames(
                            isLoginpage
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <LoginIcon
                            className={classNames(
                              isLoginpage
                                ? "text-gray-300"
                                : "text-gray-400 group-hover:text-gray-300",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          Log in
                        </NavLink>
                      </div>
                    )}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
            <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"
                alt="Workflow"
              />
            </div>
            <div className="flex-1 flex flex-col overflow-y-auto">
              <nav className="relative flex-1 py-4 space-y-1">
                {isLoggedIn && (
                  <>
                    {navigation.map((item) => (
                      <div key={item.name} className="px-2">
                        <NavLink
                          to={item.to}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-300"
                                : "text-gray-400 group-hover:text-gray-300",
                              "mr-3 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      </div>
                    ))}
                  </>
                )}

                {isLoggedIn ? (
                  <div className="absolute bottom-3 w-full px-2">
                    <div
                      onClick={onSignOutUser}
                      className={classNames(
                        isLoginpage
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-red-600 hover:text-white",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer"
                      )}
                    >
                      <LogoutIcon
                        className={classNames(
                          isLoginpage
                            ? "text-gray-300"
                            : "text-gray-400 group-hover:text-gray-300",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      Sign out
                    </div>
                  </div>
                ) : (
                  <div className="absolute bottom-3 w-full px-2">
                    <NavLink
                      to="/login"
                      className={classNames(
                        isLoginpage
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      <LoginIcon
                        className={classNames(
                          isLoginpage
                            ? "text-gray-300"
                            : "text-gray-400 group-hover:text-gray-300",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      Log in
                    </NavLink>
                  </div>
                )}
              </nav>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex">
                {!isHomepage &&
                  !isReportPage &&
                  !isReportCreate &&
                  !isLoginpage &&
                  !isProfitAndLoss &&
                  !isRecap &&
                  !isBranchDetail &&
                  !isSettings && (
                    <form className="w-full flex md:ml-0" onSubmit={onSearch}>
                      <label htmlFor="search-field" className="sr-only">
                        Search
                      </label>
                      <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                        <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                          <SearchIcon className="h-5 w-5" aria-hidden="true" />
                        </div>
                        <input
                          id="search-field"
                          className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                          placeholder="Search"
                          type="search"
                          name="search"
                          value={currentSearch}
                          onChange={(e) => setCurrentSearch(e.target.value)}
                        />
                      </div>
                    </form>
                  )}
              </div>
              {!isLoginpage && (
                <div className="ml-4 flex items-center md:ml-6">
                  <button
                    type="button"
                    className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="sr-only">Open user menu</span>
                        <div>
                          <UserCircleIcon className="h-8 w-8 text-gray-400 hover:text-gray-500" />
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-4 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={onSignOutUser}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                              )}
                            >
                              Sign out
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              )}
            </div>
          </div>

          <main className="flex-1">
            <div className="py-6">
              <Routes>
                <Route
                  path="/"
                  element={
                    <Protected>
                      <Dashboard />
                    </Protected>
                  }
                />
                <Route
                  path="/recap"
                  element={
                    <Protected>
                      <Recap />
                    </Protected>
                  }
                />
                <Route
                  path="/profit-loss"
                  element={
                    <Protected>
                      <ProfitAndLoss />
                    </Protected>
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/branch"
                  element={
                    <Protected>
                      <Branch search={search} />
                    </Protected>
                  }
                />
                <Route
                  path="/branch/detail"
                  element={
                    <Protected>
                      <BranchDetail />
                    </Protected>
                  }
                />
                <Route
                  path="/branch/manager"
                  element={
                    <Protected>
                      <BranchManager />
                    </Protected>
                  }
                />
                <Route
                  path="/report"
                  element={
                    <Protected>
                      <Report />
                    </Protected>
                  }
                />
                <Route
                  path="/report/form"
                  element={
                    <Protected>
                      <ReportForm />
                    </Protected>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <Protected>
                      <Settings />
                    </Protected>
                  }
                />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </CustomContext.Provider>
  );
}
