import React, { FC } from "react";

import services from "../../services";

import BranchBopItemTable from "../../components/BranchBopItemTable";

import { TBopItem } from "../../types/TBopItem";
import { useSearchParams } from "react-router-dom";

interface props {
  setShow: Function;
  setNotificationTitle: Function;
  bopItems: TBopItem[];
  getAllBopItems: Function;
  onEdit: Function;
}

const BopItemTable: FC<props> = ({
  setShow,
  setNotificationTitle,
  bopItems,
  getAllBopItems,
  onEdit,
}) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <BranchBopItemTable
              data={bopItems}
              setShow={setShow}
              setNotificationTitle={setNotificationTitle}
              refetchData={getAllBopItems}
              onEdit={onEdit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BopItemTable;
