import React from "react";
import { ArrowLeftIcon, PlusSmIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

import CircleButton from "../../components/CircleButton";
import BranchManagerForm from "./components/BranchManagerForm";

import Table from "./Table";

import { TManager } from "../../types/TManager";

import services from "../../services";
import { TBranch } from "../../types/TBranch";
import Notification from "../../components/Notification";

const BranchManager = () => {
  const navigate = useNavigate();

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const [notificationTitle, setNotificationTitle] = React.useState<string>("");
  const [branches, setBranches] = React.useState<TBranch[]>([]);
  const [managers, setManagers] = React.useState<TManager[]>([]);
  const [manager, setManager] = React.useState<TManager | null>(null);

  const onModalChange = () => {
    setIsOpen(!isOpen);
    if (isOpen === false) {
      setIsEdit(false);
    }
  };

  const getAllBranches = async () => {
    const data = await services.branch.getBranches();

    if (data.ok === true) {
      setBranches(data.data);
    }
  };

  const getAllManagers = async () => {
    const data = await services.manager.getManagers();

    if (data.ok === true) {
      setManagers(data.data);
    }
  };

  const getManager = async (id: string) => {
    const data = await services.manager.getManager(id);

    if (data.ok) {
      setManager(data.data);
    }
  };

  const onEdit = (id: string) => {
    setIsEdit(true);
    setIsOpen(true);
    getManager(id);
  };

  React.useEffect(() => {
    getAllBranches();
    getAllManagers();
  }, []);

  return (
    <div>
      <Notification show={show} setShow={setShow} title={notificationTitle} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex gap-x-3">
        <div onClick={() => navigate(-1)}>
          <CircleButton
            icon={<ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />}
          />
        </div>
        <h1 className="text-2xl font-semibold text-gray-900">
          Penanggung Jawab Cabang
        </h1>
        <div>
          <CircleButton
            onClick={onModalChange}
            icon={<PlusSmIcon className="h-5 w-5" aria-hidden="true" />}
          />
        </div>
      </div>

      <BranchManagerForm
        isEdit={isEdit}
        editState={manager}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        branches={branches}
        setShow={setShow}
        setNotificationTitle={setNotificationTitle}
        getAllManagers={getAllManagers}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <Table
            setShow={setShow}
            setNotificationTitle={setNotificationTitle}
            managers={managers}
            branches={branches}
            getAllManagers={getAllManagers}
            onEdit={onEdit}
          />
        </div>
      </div>
    </div>
  );
};

export default BranchManager;
