import roles from "../dummy/roles.json";

const getRoles = () => {
  return roles;
};

const business = {
  getRoles,
};

export default business;
