import React from "react";
import moment from "moment";

import SelectInput from "../../components/SelectInput";

import { TReport } from "../../types/TReport";
import { TBranch } from "../../types/TBranch";
import { TPlan } from "../../types/TPlan";
import { TBopItem } from "../../types/TBopItem";

import services from "../../services";

import CustomChart from "./CustomChart";
import { sumData, sumMonthData } from "../../utils/sum.data";

const Dashboard = () => {
  const startYear = 2000;
  const endYear = new Date().getFullYear();

  const [branchId, setBranchId] = React.useState<string>("");
  const [type, setType] = React.useState<string>("production");

  const [years, setYears] = React.useState<number[]>([]);
  const [year, setYear] = React.useState<string>(String(endYear));

  const [branches, setBranches] = React.useState<TBranch[]>([]);
  const [plans, setPlans] = React.useState<TPlan[]>([]);
  const [reports, setReports] = React.useState<TReport[]>([]);

  React.useEffect(() => {
    for (let i = startYear; i <= endYear; i++) {
      setYears((state) => [...state, i]);
    }
  }, [startYear, endYear]);

  const getAllBranches = async () => {
    const data = await services.branch.getBranches();

    if (data.ok === true) {
      setBranches(data.data);
    }
  };

  const getAllPlans = async () => {
    const data = await services.plan.getPlans();

    if (data.ok) {
      setPlans(data.data);
    }
  };

  const lastday = (y: number, m: number) => {
    return new Date(y, m, 0).getDate();
  };

  const getAllReports = async (currentYear: any) => {
    const startDate = `${currentYear}-01-01`;
    const endDate = `${currentYear}-12-${lastday(Number(currentYear), 12)}`;

    const start = moment(new Date(startDate)).startOf("day").unix();
    const end = moment(new Date(endDate)).endOf("day").unix();

    const data = await services.report.getReportsByDate(start, end);

    if (data.ok === true) {
      setReports(data.data);
    }
  };

  const sumHppItemValues = (data: any[], totalProduction: any) => {
    const total = data
      ?.map((el, i) => {
        if (el.name === "Biaya Kedelai") {
          return Number(totalProduction) * Number(data[i + 1].value);
        }
        if (i === 1) {
          return 0;
        } else {
          return Number(el.value);
        }
      })
      ?.reduce((a, b) => a + b, 0);

    return total;
  };

  const getReportPerMonth = (month: string, data: TReport[]) => {
    const splitMonth = month?.split("-");
    const y = Number(splitMonth?.[0]);
    const m = Number(splitMonth?.[1]);
    const endDay = lastday(y, m);

    const weekOne = data
      .map((el) => {
        const startDate = `${month}-1`;
        const endDate = `${month}-7`;

        const start = moment(new Date(startDate)).startOf("day").unix();
        const end = moment(new Date(endDate)).endOf("day").unix();
        if (el.date >= start && el.date <= end) {
          const day = Number(moment.unix(el.date).format("DD"));

          if (day === 7) {
            return el;
          } else {
            return {
              ...el,
              previouslySavedItems: el.previouslySavedItems.map((item) => ({
                ...item,
                value: 0,
              })),
              receivableItems: el.receivableItems.map((item) => ({
                ...item,
                value: 0,
              })),
              writeOffItems: el.writeOffItems.map((item) => ({
                ...item,
                value: 0,
              })),
            };
          }
        }

        return null;
      })
      .filter((el) => el !== null);

    const weekTwo = data
      .map((el) => {
        const startDate = `${month}-8`;
        const endDate = `${month}-14`;

        const start = moment(new Date(startDate)).startOf("day").unix();
        const end = moment(new Date(endDate)).endOf("day").unix();
        if (el.date >= start && el.date <= end) {
          const day = Number(moment.unix(el.date).format("DD"));

          if (day === 14) {
            return el;
          } else {
            return {
              ...el,
              previouslySavedItems: el.previouslySavedItems.map((item) => ({
                ...item,
                value: 0,
              })),
              receivableItems: el.receivableItems.map((item) => ({
                ...item,
                value: 0,
              })),
              writeOffItems: el.writeOffItems.map((item) => ({
                ...item,
                value: 0,
              })),
            };
          }
        }

        return null;
      })
      .filter((el) => el !== null);

    const weekThree = data
      .map((el) => {
        const startDate = `${month}-15`;
        const endDate = `${month}-21`;

        const start = moment(new Date(startDate)).startOf("day").unix();
        const end = moment(new Date(endDate)).endOf("day").unix();
        if (el.date >= start && el.date <= end) {
          const day = Number(moment.unix(el.date).format("DD"));

          if (day === 21) {
            return el;
          } else {
            return {
              ...el,
              previouslySavedItems: el.previouslySavedItems.map((item) => ({
                ...item,
                value: 0,
              })),
              receivableItems: el.receivableItems.map((item) => ({
                ...item,
                value: 0,
              })),
              writeOffItems: el.writeOffItems.map((item) => ({
                ...item,
                value: 0,
              })),
            };
          }
        }

        return null;
      })
      .filter((el) => el !== null);

    const weekFour = data
      .map((el) => {
        const startDate = `${month}-22`;
        const endDate = `${month}-${endDay}`;

        const start = moment(new Date(startDate)).startOf("day").unix();
        const end = moment(new Date(endDate)).endOf("day").unix();
        if (el.date >= start && el.date <= end) {
          const day = Number(moment.unix(el.date).format("DD"));

          if (day === Number(endDay)) {
            return el;
          } else {
            return {
              ...el,
              previouslySavedItems: el.previouslySavedItems.map((item) => ({
                ...item,
                value: 0,
              })),
              receivableItems: el.receivableItems.map((item) => ({
                ...item,
                value: 0,
              })),
              writeOffItems: el.writeOffItems.map((item) => ({
                ...item,
                value: 0,
              })),
            };
          }
        }

        return null;
      })
      .filter((el) => el !== null);

    const weekOneLength = weekOne.filter((el) => el?.totalProduction).length;
    const weekTwoLength = weekTwo.filter((el) => el?.totalProduction).length;
    const weekThreeLength = weekThree.filter(
      (el) => el?.totalProduction
    ).length;
    const weekFourLength = weekFour.filter((el) => el?.totalProduction).length;

    const realMonthLength =
      weekOneLength + weekTwoLength + weekThreeLength + weekFourLength;

    const sumWeekOne = sumData(weekOne, 7);
    const sumWeekTwo = sumData(weekTwo, 7);
    const sumWeekThree = sumData(weekThree, 7);
    const sumWeekFour = sumData(weekFour, endDay - 21);

    const allWeekResult = [
      sumWeekOne,
      sumWeekTwo,
      sumWeekThree,
      sumWeekFour,
    ].filter((el) => el !== null);

    const selectedMonth = allWeekResult
      .map((el, i) => {
        if (i === 3) {
          return el;
        } else {
          return {
            ...el,
            previouslySavedItems: el.previouslySavedItems.map((item: any) => ({
              ...item,
              value: 0,
            })),
            receivableItems: el.receivableItems.map((item: any) => ({
              ...item,
              value: 0,
            })),
            writeOffItems: el.writeOffItems.map((item: any) => ({
              ...item,
              value: 0,
            })),
          };
        }
      })
      .filter((el) => el !== null);

    const result = sumMonthData(selectedMonth, 4);

    const recapSelectedMonth = {
      production: Math.round(result?.totalProduction / realMonthLength),
      profit: Math.round(
        Number(result?.otherIncome) +
          Number(result?.revenueRealization) -
          result?.totalHpp -
          sumBopValues(result?.bopItems)
      ),
    };

    return recapSelectedMonth;
  };

  const jan = [`${year}-01-01`, `${year}-01-${lastday(Number(year), 1)}`];
  const feb = [`${year}-02-01`, `${year}-02-${lastday(Number(year), 2)}`];
  const mar = [`${year}-03-01`, `${year}-03-${lastday(Number(year), 3)}`];
  const apr = [`${year}-04-01`, `${year}-04-${lastday(Number(year), 4)}`];
  const may = [`${year}-05-01`, `${year}-05-${lastday(Number(year), 5)}`];
  const jun = [`${year}-06-01`, `${year}-06-${lastday(Number(year), 6)}`];
  const jul = [`${year}-07-01`, `${year}-07-${lastday(Number(year), 7)}`];
  const aug = [`${year}-08-01`, `${year}-08-${lastday(Number(year), 8)}`];
  const sep = [`${year}-09-01`, `${year}-09-${lastday(Number(year), 9)}`];
  const oct = [`${year}-10-01`, `${year}-10-${lastday(Number(year), 10)}`];
  const nov = [`${year}-11-01`, `${year}-11-${lastday(Number(year), 11)}`];
  const des = [`${year}-12-01`, `${year}-12-${lastday(Number(year), 12)}`];

  const datesCondition = (dates: string[], currentDate: number) => {
    const start = moment(new Date(dates[0])).startOf("day").unix();
    const end = moment(new Date(dates[1])).endOf("day").unix();

    return currentDate >= start && currentDate <= end;
  };

  const sumBopValues = (data: any[]) => {
    const total = data
      ?.map((el) => Number(el.value))
      ?.reduce((a, b) => a + b, 0);

    return total;
  };

  const generateDataChart = () => {
    const result: any[] = [];

    branches.forEach((branch) => {
      const resultPerMonth: any = {
        jan: {
          branchId: branch.id,
          reports: [],
          plan: 0,
          month: `${year}-01`,
          days: lastday(Number(year), 1),
        },
        feb: {
          branchId: branch.id,
          reports: [],
          plan: 0,
          month: `${year}-02`,
          days: lastday(Number(year), 2),
        },
        mar: {
          branchId: branch.id,
          reports: [],
          plan: 0,
          month: `${year}-03`,
          days: lastday(Number(year), 3),
        },
        apr: {
          branchId: branch.id,
          reports: [],
          plan: 0,
          month: `${year}-04`,
          days: lastday(Number(year), 4),
        },
        may: {
          branchId: branch.id,
          reports: [],
          plan: 0,
          month: `${year}-05`,
          days: lastday(Number(year), 5),
        },
        jun: {
          branchId: branch.id,
          reports: [],
          plan: 0,
          month: `${year}-06`,
          days: lastday(Number(year), 6),
        },
        jul: {
          branchId: branch.id,
          reports: [],
          plan: 0,
          month: `${year}-07`,
          days: lastday(Number(year), 7),
        },
        aug: {
          branchId: branch.id,
          reports: [],
          plan: 0,
          month: `${year}-08`,
          days: lastday(Number(year), 8),
        },
        sep: {
          branchId: branch.id,
          reports: [],
          plan: 0,
          month: `${year}-09`,
          days: lastday(Number(year), 9),
        },
        oct: {
          branchId: branch.id,
          reports: [],
          plan: 0,
          month: `${year}-10`,
          days: lastday(Number(year), 10),
        },
        nov: {
          branchId: branch.id,
          reports: [],
          plan: 0,
          month: `${year}-11`,
          days: lastday(Number(year), 11),
        },
        des: {
          branchId: branch.id,
          reports: [],
          plan: 0,
          month: `${year}-12`,
          days: lastday(Number(year), 12),
        },
      };

      reports.forEach((report) => {
        if (report.BranchId === branch.id) {
          if (datesCondition(jan, report.date)) {
            const plan = plans.find(
              (el) =>
                datesCondition(jan, el.date) && el.BranchId === report.BranchId
            );

            resultPerMonth.jan.plan = {
              totalProduction: plan?.totalProduction,
              totalProfit: plan?.totalProfit,
            };
            resultPerMonth.jan.reports.push(report);
          }

          if (datesCondition(feb, report.date)) {
            const plan = plans.find(
              (el) =>
                datesCondition(feb, el.date) && el.BranchId === report.BranchId
            );

            resultPerMonth.feb.plan = {
              totalProduction: plan?.totalProduction,
              totalProfit: plan?.totalProfit,
            };
            resultPerMonth.feb.reports.push(report);
          }

          if (datesCondition(mar, report.date)) {
            const plan = plans.find(
              (el) =>
                datesCondition(mar, el.date) && el.BranchId === report.BranchId
            );

            resultPerMonth.mar.plan = {
              totalProduction: plan?.totalProduction,
              totalProfit: plan?.totalProfit,
            };
            resultPerMonth.mar.reports.push(report);
          }

          if (datesCondition(apr, report.date)) {
            const plan = plans.find(
              (el) =>
                datesCondition(apr, el.date) && el.BranchId === report.BranchId
            );

            resultPerMonth.apr.plan = {
              totalProduction: plan?.totalProduction,
              totalProfit: plan?.totalProfit,
            };
            resultPerMonth.apr.reports.push(report);
          }

          if (datesCondition(may, report.date)) {
            const plan = plans.find(
              (el) =>
                datesCondition(may, el.date) && el.BranchId === report.BranchId
            );

            resultPerMonth.may.plan = {
              totalProduction: plan?.totalProduction,
              totalProfit: plan?.totalProfit,
            };
            resultPerMonth.may.reports.push(report);
          }

          if (datesCondition(jun, report.date)) {
            const plan = plans.find(
              (el) =>
                datesCondition(jun, el.date) && el.BranchId === report.BranchId
            );

            resultPerMonth.jun.plan = {
              totalProduction: plan?.totalProduction,
              totalProfit: plan?.totalProfit,
            };
            resultPerMonth.jun.reports.push(report);
          }

          if (datesCondition(jul, report.date)) {
            const plan = plans.find(
              (el) =>
                datesCondition(jul, el.date) && el.BranchId === report.BranchId
            );

            resultPerMonth.jul.plan = {
              totalProduction: plan?.totalProduction,
              totalProfit: plan?.totalProfit,
            };
            resultPerMonth.jul.reports.push(report);
          }

          if (datesCondition(aug, report.date)) {
            const plan = plans.find(
              (el) =>
                datesCondition(aug, el.date) && el.BranchId === report.BranchId
            );

            resultPerMonth.aug.plan = {
              totalProduction: plan?.totalProduction,
              totalProfit: plan?.totalProfit,
            };
            resultPerMonth.aug.reports.push(report);
          }

          if (datesCondition(sep, report.date)) {
            const plan = plans.find(
              (el) =>
                datesCondition(sep, el.date) && el.BranchId === report.BranchId
            );

            resultPerMonth.sep.plan = {
              totalProduction: plan?.totalProduction,
              totalProfit: plan?.totalProfit,
            };
            resultPerMonth.sep.reports.push(report);
          }

          if (datesCondition(oct, report.date)) {
            const plan = plans.find(
              (el) =>
                datesCondition(oct, el.date) && el.BranchId === report.BranchId
            );

            resultPerMonth.oct.plan = {
              totalProduction: plan?.totalProduction,
              totalProfit: plan?.totalProfit,
            };
            resultPerMonth.oct.reports.push(report);
          }

          if (datesCondition(nov, report.date)) {
            const plan = plans.find(
              (el) =>
                datesCondition(nov, el.date) && el.BranchId === report.BranchId
            );

            resultPerMonth.nov.plan = {
              totalProduction: plan?.totalProduction,
              totalProfit: plan?.totalProfit,
            };
            resultPerMonth.nov.reports.push(report);
          }

          if (datesCondition(des, report.date)) {
            const plan = plans.find(
              (el) =>
                datesCondition(des, el.date) && el.BranchId === report.BranchId
            );

            resultPerMonth.des.plan = {
              totalProduction: plan?.totalProduction,
              totalProfit: plan?.totalProfit,
            };
            resultPerMonth.des.reports.push(report);
          }
        }
      });

      const calculateResultPerMonth: any[] = [];
      let index = 1;

      for (let key in resultPerMonth) {
        const currentMonth = resultPerMonth[key].month;
        const currentReports = resultPerMonth[key].reports;
        const reportPerMonth = getReportPerMonth(currentMonth, currentReports);

        const totalProfit = reportPerMonth?.profit;
        const totalProduction = reportPerMonth?.production;

        const payload = {
          id: index,
          month: key.toUpperCase(),
          production: {
            actual:
              totalProduction?.toFixed(0) !== "NaN"
                ? totalProduction?.toFixed(0)
                : 0,
            plan: Number(resultPerMonth[key].plan.totalProduction) || 0,
          },
          profit: {
            actual: totalProfit || 0,
            plan: Number(resultPerMonth[key].plan.totalProfit) || 0,
          },
        };

        index++;

        calculateResultPerMonth.push(payload);
      }

      result.push({
        branchId: branch.id,
        branchName: branch.name,
        data: calculateResultPerMonth,
      });
    });

    return result;
  };

  const chartData = generateDataChart();

  React.useEffect(() => {
    getAllBranches();
    getAllPlans();
    getAllReports(year);
  }, [year]);

  React.useEffect(() => {
    if (!branchId) {
      setBranchId(branches?.[0]?.id!);
    }
  }, [branches, branchId]);

  const yearOptions = years.map((el) => ({
    id: String(el),
    label: String(el),
  }));
  const typeOptions = [
    {
      id: "production",
      label: "Produksi",
    },
    {
      id: "profit",
      label: "Profit",
    },
  ];

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col sm:flex-row sm:justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        <div className="flex flex-col sm:flex-row gap-x-2">
          <SelectInput
            id="type"
            value={type}
            setValue={setType}
            values={typeOptions}
            placeholder="Tipe"
          />
          <SelectInput
            id="year"
            value={year}
            setValue={setYear}
            values={yearOptions}
            placeholder="Tahun"
          />
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        {/* Replace with your content */}
        <div className="mt-5">
          {chartData.map((chart) => (
            <div key={chart.branchId}>
              <div>
                <h2>{chart.branchName}</h2>
              </div>
              <CustomChart data={chart} type={type} />
            </div>
          ))}
        </div>
        {/* /End replace */}
      </div>
    </div>
  );
};

export default Dashboard;
