import React, { FC } from "react";
import Papa from "papaparse";
import moment from "moment";
import { TrashIcon } from "@heroicons/react/outline";

import Button from "../../components/Button";

import services from "../../services";

import { useCustomContext } from "../../hooks/useCustomContext";

interface props {
  setShowBatchCreate: Function;
}

const CreateBatchReport: FC<props> = ({ setShowBatchCreate }) => {
  const context = useCustomContext();

  const [loading, setLoading] = React.useState<boolean>(false);

  const [currentFile, setCurrentFile] = React.useState<any>(null);
  const [reportInputs, setReportInputs] = React.useState<any[]>([]);

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    setLoading(true);

    if (reportInputs.length !== 0) {
      const data = await services.report.createBatchReport(reportInputs);

      if (data.ok) {
        setShowBatchCreate(false);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const getCsvData = (result: any) => {
    const globalItems = context.items;

    const data = result.data;
    const iterator = data[0].length;

    const productionIndexes: number[] = [];
    const previouslySavedIndexes: number[] = [];
    const soldIndexes: number[] = [];
    const receivableIndexes: number[] = [];
    const returnIndexes: number[] = [];
    const writeOffIndexes: number[] = [];
    const hppIndexes: number[] = [];
    const bopIndexes: number[] = [];

    data.forEach((el: any[], i: number) => {
      if (el[0] === "production") {
        productionIndexes.push(i + 1);
      }

      if (el[0] === "previouslySaved") {
        productionIndexes.push(i);
        previouslySavedIndexes.push(i + 1);
      }

      if (el[0] === "sold") {
        previouslySavedIndexes.push(i);
        soldIndexes.push(i + 1);
      }

      if (el[0] === "receivable") {
        soldIndexes.push(i);
        receivableIndexes.push(i + 1);
      }

      if (el[0] === "return") {
        receivableIndexes.push(i);
        returnIndexes.push(i + 1);
      }

      if (el[0] === "writeOff") {
        returnIndexes.push(i);
        writeOffIndexes.push(i + 1);
      }

      if (el[0] === "hpp") {
        writeOffIndexes.push(i);
        hppIndexes.push(i + 1);
      }

      if (el[0] === "bop") {
        hppIndexes.push(i);
        bopIndexes.push(i + 1);
        bopIndexes.push(data.length);
      }
    });

    const productionItemsRaw = data.slice(
      productionIndexes[0],
      productionIndexes[1]
    );
    const previouslySavedItemsRaw = data.slice(
      previouslySavedIndexes[0],
      previouslySavedIndexes[1]
    );
    const soldItemsRaw = data.slice(soldIndexes[0], soldIndexes[1]);
    const receivableItemsRaw = data.slice(
      receivableIndexes[0],
      receivableIndexes[1]
    );
    const returnItemsRaw = data.slice(returnIndexes[0], returnIndexes[1]);
    const writeOffItemsRaw = data.slice(writeOffIndexes[0], writeOffIndexes[1]);
    const hppItemsRaw = data.slice(hppIndexes[0], hppIndexes[1]);
    const bopItemsRaw = data.slice(bopIndexes[0], bopIndexes[1]);

    const reportInput: any[] = [];

    for (let i = 1; i < iterator; i++) {
      const productionItems = productionItemsRaw.map(
        (el: any[], index: number) => ({
          id: index,
          name: el[0],
          value: el[i],
          other:
            globalItems.find(
              (item) => item.name === el[0] && item.BranchId === data[0][i]
            )?.weight || null,
        })
      );
      const previouslySavedItems = previouslySavedItemsRaw.map(
        (el: any[], index: number) => ({
          id: index,
          name: el[0],
          value: el[i],
        })
      );
      const soldItems = soldItemsRaw.map((el: any[], index: number) => ({
        id: index,
        name: el[0],
        value: el[i],
      }));
      const receivableItems = receivableItemsRaw.map(
        (el: any[], index: number) => ({
          id: index,
          name: el[0],
          value: el[i],
        })
      );
      const returnItems = returnItemsRaw.map((el: any[], index: number) => ({
        id: index,
        name: el[0],
        value: el[i],
      }));
      const writeOffItems = writeOffItemsRaw.map(
        (el: any[], index: number) => ({
          id: index,
          name: el[0],
          value: el[i],
        })
      );
      const hppItems = hppItemsRaw.map((el: any[], index: number) => ({
        id: index,
        name: el[0],
        value: el[i],
      }));
      const bopItems = bopItemsRaw.map((el: any[], index: number) => ({
        id: index,
        name: el[0],
        value: el[i],
      }));

      reportInput.push({
        BranchId: data[0][i],
        totalProduction: data[1][i],
        date: moment(new Date(data[2][i])).unix(),
        otherIncome: data[3][i],
        revenueRealization: data[4][i],
        productionItems,
        previouslySavedItems,
        soldItems,
        receivableItems,
        returnItems,
        writeOffItems,
        hppItems,
        bopItems,
        createdAt: moment().unix(),
        updatedAt: moment().unix(),
      });
    }

    setReportInputs(reportInput);
  };

  const onChangeFile = (e: any) => {
    const file = e.target.files?.[0];
    setCurrentFile(e.target.files);
    e.target.value = "";

    Papa.parse(file, {
      complete: getCsvData,
      delimitersToGuess: [",", ";"],
    });
  };

  const onRemoveFile = () => {
    setCurrentFile(null);
    setReportInputs([]);
  };

  return (
    <div className="mt-5">
      <form>
        <div className="flex">
          {currentFile ? (
            <div className="flex gap-x-3 items-center justify-center rounded-lg border-2 border-dashed w-full text-center p-5 cursor-pointer">
              <p>Uploaded</p>
              <TrashIcon
                onClick={onRemoveFile}
                className="w-5 h-5 text-red-500"
              />
            </div>
          ) : (
            <label
              htmlFor="batchCreate"
              className="border-2 border-dashed rounded-lg w-full text-center p-5 cursor-pointer"
            >
              Upload File (.csv)
            </label>
          )}
        </div>
        <input
          id="batchCreate"
          className="hidden"
          type="file"
          accept=".csv"
          onChange={onChangeFile}
        />
        <div className="text-right mt-3">
          <Button
            disabled={loading}
            loading={loading ? "true" : "false"}
            onClick={onSubmit}
            label="Submit"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateBatchReport;
