import "./utils/firebase";

import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";
import "moment/locale/id";

import Pages from "./pages";

function App() {
  return (
    <Router>
      <Pages />;
    </Router>
  );
}

export default App;
