import { DocumentSnapshot } from "./TFirebase";

export type TReportItem = {
  id?: any;
  name: string;
  value: any;
  other?: any;
};

export type TReport = {
  id?: string;
  date: number;
  BranchId: string;
  totalProduction: string;
  productionItems: TReportItem[];
  previouslySavedItems: TReportItem[];
  soldItems: TReportItem[];
  receivableItems: TReportItem[];
  returnItems: TReportItem[];
  writeOffItems: TReportItem[];
  otherIncome: string;
  revenueRealization: string;
  hppItems: TReportItem[];
  bopItems: TReportItem[];
  createdAt: number;
  updatedAt: number;
};

export const tReportConverter = {
  fromFirestore: (snapshot: DocumentSnapshot): TReport => {
    return { ...snapshot.data(), id: snapshot.id } as unknown as TReport;
  },
  toFirestore: (report: TReport) => {
    return report;
  },
};
