import classNames from "classnames";
import { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";

import services from "../services";

import { currency } from "../utils/currency";

import ModalConfirmDelete from "./ModalConfirmDelete";

interface props {
  data: any[];
  setShow: Function;
  setNotificationTitle: Function;
  refetchData: Function;
  onEdit: Function;
}

const BranchBopItemTable: FC<props> = ({
  data,
  setShow,
  setNotificationTitle,
  refetchData,
  onEdit,
}) => {
  const [search] = useSearchParams();

  const branchId = search.get("id");

  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  const onDelete = async () => {
    const remove = await services.bopItem.deleteBopItem(id);

    if (remove.ok) {
      setShow(true);
      setNotificationTitle(remove.message);
      refetchData(branchId);
    }
  };

  const onConfirmDelete = (id: string) => {
    setId(id);
    setOpen(true);
  };

  return (
    <>
      <ModalConfirmDelete open={open} setOpen={setOpen} onDelete={onDelete} />
      <table className="min-w-full divide-y divide-gray-200 border">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Default Amount (Rp)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Status
            </th>
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((el: any) => (
            <tr key={el.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex flex-col">
                  <div className="text-sm font-medium text-gray-900">
                    {el.name}
                  </div>
                  <div className="text-sm text-gray-500">{el.phone}</div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {currency(el.defaultAmount)}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap flex flex-col flex-y-2">
                <div>
                  <span
                    className={classNames(
                      el.isDepositComponent
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800",
                      "px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                    )}
                  >
                    Komponen Setoran
                  </span>
                </div>
                <div>
                  <span
                    className={classNames(
                      el.isEducationBudget
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800",
                      "px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                    )}
                  >
                    Komponen Dana Pendidikan
                  </span>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <div className="inline-flex">
                  <div
                    onClick={() => onEdit(el.id)}
                    className="cursor-pointer text-indigo-600 hover:text-indigo-900"
                  >
                    Edit
                  </div>
                  <div
                    onClick={() => onConfirmDelete(el?.id!)}
                    className="cursor-pointer ml-2 text-red-600 hover:text-red-900"
                  >
                    Delete
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default BranchBopItemTable;
