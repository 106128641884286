import { getApp, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

initializeApp(
  {
    apiKey: "AIzaSyBP0RRQYjjQzO5NlUDwlqCIhuwDGOK_yvA",
    authDomain: "tempeh-b8df3.firebaseapp.com",
    projectId: "tempeh-b8df3",
    storageBucket: "tempeh-b8df3.appspot.com",
    messagingSenderId: "737817519547",
    appId: "1:737817519547:web:161db983401ee6b22f909e",
  },
  "tempeh"
);
export const app = getApp("tempeh");
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

/*
if (window.location.hostname === "localhost") {
  console.log(process.env.NODE_ENV, "running with emulator");

  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
}
*/
