import { FC, useState } from "react";

import services from "../services";

import { TBranch } from "../types/TBranch";
import { TManager } from "../types/TManager";

import ModalConfirmDelete from "./ModalConfirmDelete";

interface props {
  data: TManager[];
  branches: TBranch[];
  setShow: Function;
  setNotificationTitle: Function;
  refetchData: Function;
  onEdit: Function;
}

const BranchManagerTable: FC<props> = ({
  data,
  branches,
  setShow,
  setNotificationTitle,
  refetchData,
  onEdit,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  const onDelete = async () => {
    const remove = await services.manager.deleteManager(id);

    if (remove.ok) {
      setShow(true);
      setNotificationTitle(remove.message);
      refetchData();
    }
  };

  const onConfirmDelete = (id: string) => {
    setId(id);
    setOpen(true);
  };

  return (
    <>
      <ModalConfirmDelete open={open} setOpen={setOpen} onDelete={onDelete} />
      <table className="min-w-full divide-y divide-gray-200 border">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Name
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Cabang
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Status
            </th>
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((el) => (
            <tr key={el.phone}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex flex-col">
                  <div className="text-sm font-medium text-gray-900">
                    {el.name}
                  </div>
                  <div className="text-sm text-gray-500">{el.phone}</div>
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">
                  {branches.find((branch) => branch.id === el.BranchId)?.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {el.status}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <div className="inline-flex">
                  <div
                    onClick={() => onEdit(el.id)}
                    className="cursor-pointer text-indigo-600 hover:text-indigo-900"
                  >
                    Edit
                  </div>
                  <div
                    onClick={() => onConfirmDelete(el?.id!)}
                    className="cursor-pointer ml-2 text-red-600 hover:text-red-900"
                  >
                    Delete
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default BranchManagerTable;
