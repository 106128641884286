import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { firestore } from "../utils/firebase";
import { success, fail, notFound } from "../utils/status.message";

import { TItem, tItemConverter } from "../types/TItem";

const itemRef = collection(firestore, "items");

const getItems = async () => {
  try {
    const querySnapshot = await getDocs(itemRef.withConverter(tItemConverter));
    const data = querySnapshot.docs.map((el) => el.data());

    return success("Berhasil mendapatkan semua data produk", data);
  } catch (error) {
    return fail();
  }
};

const getItemsByBranchId = async (id: string) => {
  try {
    const querySnapshot = await getDocs(
      query(
        itemRef.withConverter(tItemConverter),
        where("BranchId", "==", id),
        orderBy("name", "asc")
      )
    );
    const data = querySnapshot.docs.map((el) => el.data());

    return success("Berhasil mendapatkan semua data produk", data);
  } catch (error) {
    return fail();
  }
};

const getItem = async (id: string) => {
  try {
    const docRef = doc(itemRef, id);
    const docSnap = await getDoc(docRef.withConverter(tItemConverter));

    if (docSnap.exists()) {
      return success("Berhasil mendapatkan data produk", docSnap.data());
    } else {
      return notFound();
    }
  } catch (error) {
    return fail();
  }
};

const createItem = async (item: TItem) => {
  try {
    const docRef = doc(itemRef);
    await setDoc(docRef, item);

    return success("Berhasil membuat produk", item);
  } catch (error) {
    return fail();
  }
};

const updateItem = async (id: string, item: TItem) => {
  try {
    const docRef = doc(firestore, "items", id);
    await updateDoc(docRef, item);

    return success("Berhasil memperbarui produk", item);
  } catch (error) {
    return fail();
  }
};

const deleteItem = async (id: string) => {
  try {
    const docRef = doc(firestore, "items", id);
    await deleteDoc(docRef);

    return success("Berhasil menghapus produk", null);
  } catch (error) {
    return fail();
  }
};

const item = {
  getItems,
  getItemsByBranchId,
  getItem,
  createItem,
  updateItem,
  deleteItem,
};

export default item;
