import { createContext, useContext } from "react";
import { TItem } from "../types/TItem";
import { TSetting } from "../types/TSetting";

export type TCustomContext = {
  items: TItem[];
  getItems: Function;
  setting: TSetting | null;
  getSetting: Function;
};

export const CustomContext = createContext<TCustomContext>({
  items: [],
  getItems: () => {},
  setting: null,
  getSetting: () => {},
});

export const useCustomContext = () => useContext(CustomContext);
