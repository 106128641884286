import role from "./role.service";
import branch from "./branch.service";
import manager from "./manager.service";
import item from "./item.service";
import bopItem from "./bop.item.service";
import report from "./report.service";
import plan from "./plan.service";
import setting from "./setting.service";

const services = {
  role,
  branch,
  manager,
  item,
  bopItem,
  report,
  plan,
  setting,
};

export default services;
