import { FC } from "react";
import classNames from "classnames";

interface props {
  id: string;
  rows?: number;
  placeholder?: string;
  label: string;
  value: string;
  setValue: Function;
  isError?: boolean;
  errorMessage?: string;
  required?: boolean;
}

const TextAreaInput: FC<props> = ({
  id,
  rows = 3,
  placeholder,
  label,
  value,
  setValue,
  isError,
  errorMessage,
  required = false,
}) => {
  const primaryColor =
    "border-gray-300 text-gray-900 placeholder-gray-300 focus:ring-indigo-500 focus:border-indigo-500";
  const errorColor =
    "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500";

  if (!required) {
    return (
      <div>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            id={id}
            name={id}
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className={classNames(
              primaryColor,
              "block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
            )}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          id={id}
          name={id}
          rows={rows}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className={classNames(
            isError && !value ? errorColor : primaryColor,
            "block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
          )}
        />
      </div>
      {isError && !value && (
        <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
      )}
    </div>
  );
};

export default TextAreaInput;
