import { DocumentSnapshot } from "./TFirebase";

export type TItem = {
  id?: string;
  name: string;
  BranchId: string;
  weight: string;
  createdAt: number;
  updatedAt: number;
};

export const tItemConverter = {
  fromFirestore: (snapshot: DocumentSnapshot): TItem => {
    return { ...snapshot.data(), id: snapshot.id } as unknown as TItem;
  },
  toFirestore: (item: TItem) => {
    return item;
  },
};
