import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { firestore } from "../utils/firebase";
import { success, fail, notFound } from "../utils/status.message";

import { TBopItem, tBopItemConverter } from "../types/TBopItem";

const bopItemRef = collection(firestore, "bopItems");

const getBopItems = async () => {
  try {
    const querySnapshot = await getDocs(
      bopItemRef.withConverter(tBopItemConverter)
    );
    const data = querySnapshot.docs.map((el) => el.data());

    return success("Berhasil mendapatkan semua data bop item", data);
  } catch (error) {
    return fail();
  }
};

const getBopItemsByBranchId = async (id: string) => {
  try {
    const querySnapshot = await getDocs(
      query(
        bopItemRef.withConverter(tBopItemConverter),
        where("BranchId", "==", id),
        orderBy("number", "asc")
      )
    );
    const data = querySnapshot.docs.map((el) => el.data());

    return success("Berhasil mendapatkan semua data bop item", data);
  } catch (error) {
    return fail();
  }
};

const getBopItem = async (id: string) => {
  try {
    const docRef = doc(bopItemRef, id);
    const docSnap = await getDoc(docRef.withConverter(tBopItemConverter));

    if (docSnap.exists()) {
      return success("Berhasil mendapatkan data bop item", docSnap.data());
    } else {
      return notFound();
    }
  } catch (error) {
    return fail();
  }
};

const createBopItem = async (item: TBopItem) => {
  try {
    const docRef = doc(bopItemRef);
    await setDoc(docRef, item);

    return success("Berhasil membuat bop item", item);
  } catch (error) {
    return fail();
  }
};

const updateBopItem = async (id: string, item: TBopItem) => {
  try {
    const docRef = doc(firestore, "bopItems", id);
    await updateDoc(docRef, item);

    return success("Berhasil memperbarui bop item", item);
  } catch (error) {
    return fail();
  }
};

const deleteBopItem = async (id: string) => {
  try {
    const docRef = doc(firestore, "bopItems", id);
    await deleteDoc(docRef);

    return success("Berhasil menghapus bop item", null);
  } catch (error) {
    return fail();
  }
};

const item = {
  getBopItems,
  getBopItemsByBranchId,
  getBopItem,
  createBopItem,
  updateBopItem,
  deleteBopItem,
};

export default item;
