import React from "react";
import { ArrowLeftIcon, PlusSmIcon } from "@heroicons/react/solid";
import { useNavigate, useSearchParams } from "react-router-dom";

import CircleButton from "../../components/CircleButton";

import { TBranch } from "../../types/TBranch";
import { TItem } from "../../types/TItem";
import { TBopItem } from "../../types/TBopItem";
import { TPlan } from "../../types/TPlan";

import services from "../../services";

import Tab from "./Tab";
import ItemTable from "./ItemTable";
import BopItemTable from "./BopItemTable";
import PlanTable from "./PlanTable";

import BranchItemForm from "./components/BranchItemForm";
import BranchBopItemForm from "./components/BranchBopItemForm";
import Notification from "../../components/Notification";
import BranchPlanForm from "./components/BranchPlanForm";
import DownloadReportTemplate from "../../components/DownloadReportTemplate";

enum tabs {
  items = "Items",
  bopItems = "Bop Items",
  plans = "Plans",
}

const BranchDetail = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const branchId = search.get("id") as string;

  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const [notificationTitle, setNotificationTitle] = React.useState<string>("");
  const [isOpenItem, setIsOpenItem] = React.useState(false);
  const [isOpenBopItem, setIsOpenBopItem] = React.useState(false);
  const [isOpenPlan, setIsOpenPlan] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState<tabs | "">("");

  const [branch, setBranch] = React.useState<TBranch | null>(null);

  const [items, setItems] = React.useState<TItem[]>([]);
  const [item, setItem] = React.useState<TItem | null>(null);

  const [bopItems, setBopItems] = React.useState<TBopItem[]>([]);
  const [bopItem, setBopItem] = React.useState<TBopItem | null>(null);

  const [plans, setPlans] = React.useState<TPlan[]>([]);
  const [plan, setPlan] = React.useState<TPlan | null>(null);

  const onModalItemChange = () => {
    setIsOpenItem(!isOpenItem);
    if (isOpenItem === false) {
      setIsEdit(false);
    }
  };

  const onModalBopItemChange = () => {
    setIsOpenBopItem(!isOpenBopItem);
    if (isOpenBopItem === false) {
      setIsEdit(false);
    }
  };

  const onModalPlanChange = () => {
    setIsOpenPlan(!isOpenPlan);
    if (isOpenPlan === false) {
      setIsEdit(false);
    }
  };

  const getOneBranch = async (id: string) => {
    const data = await services.branch.getBranch(id);

    if (data.ok) {
      setBranch(data.data);
    }
  };

  React.useEffect(() => {
    getOneBranch(branchId);
  }, [branchId]);

  const getAllItems = async (id: string) => {
    const data = await services.item.getItemsByBranchId(id);

    if (data.ok) {
      setItems(data.data);
    }
  };

  const getItem = async (id: string) => {
    const data = await services.item.getItem(id);

    if (data.ok) {
      setItem(data.data);
    }
  };

  const onEditItem = (id: string) => {
    setIsEdit(true);
    setIsOpenItem(true);
    getItem(id);
  };

  const getAllBopItems = async (id: string) => {
    const data = await services.bopItem.getBopItemsByBranchId(id);

    if (data.ok) {
      setBopItems(data.data);
    }
  };

  const getBopItem = async (id: string) => {
    const data = await services.bopItem.getBopItem(id);

    if (data.ok) {
      setBopItem(data.data);
    }
  };

  const onEditBopItem = (id: string) => {
    setIsEdit(true);
    setIsOpenBopItem(true);
    getBopItem(id);
  };

  const getAllPlans = async (id: string) => {
    const data = await services.plan.getPlansByBranchId(id);

    if (data.ok) {
      setPlans(data.data);
    }
  };

  const getPlan = async (id: string) => {
    const data = await services.plan.getPlan(id);

    if (data.ok) {
      setPlan(data.data);
    }
  };

  const onEditPlan = (id: string) => {
    setIsEdit(true);
    setIsOpenPlan(true);
    getPlan(id);
  };

  React.useEffect(() => {
    getAllItems(branchId);
    getAllBopItems(branchId);
    getAllPlans(branchId);
  }, [branchId]);

  const listItem = items.map((el) => [el.name]);
  const listHppItem = [
    ["Biaya Kedelai"],
    ["Harga Kedelai Rp/Kg"],
    ["Plastik"],
    ["Gas"],
    ["Kayu Bakar (boiler)"],
    ["Ragi + Asoy"],
    ["Other"],
  ];
  const listBopItem = bopItems.map((el) => [el.name]);

  return (
    <div>
      <Notification show={show} setShow={setShow} title={notificationTitle} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex gap-x-3">
        <div onClick={() => navigate(-1)}>
          <CircleButton
            icon={<ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />}
          />
        </div>
        <h1 className="text-2xl font-semibold text-gray-900">{`Cabang ${branch?.name}`}</h1>
        <div>
          {currentTab === tabs.items && (
            <CircleButton
              onClick={onModalItemChange}
              icon={<PlusSmIcon className="h-5 w-5" aria-hidden="true" />}
            />
          )}
          {currentTab === tabs.bopItems && (
            <CircleButton
              onClick={onModalBopItemChange}
              icon={<PlusSmIcon className="h-5 w-5" aria-hidden="true" />}
            />
          )}
          {currentTab === tabs.plans && (
            <CircleButton
              onClick={onModalPlanChange}
              icon={<PlusSmIcon className="h-5 w-5" aria-hidden="true" />}
            />
          )}
        </div>
      </div>

      <div className="sm:flex items-center justify-between mt-5 sm:mt-0 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Tab currentTab={currentTab} setCurrentTab={setCurrentTab} />
        <div className="mt-5 text-right">
          <DownloadReportTemplate
            branchName={branch?.name}
            branchId={branchId}
            listItem={listItem}
            listHppItem={listHppItem}
            listBopItem={listBopItem}
          />
        </div>
      </div>

      <BranchItemForm
        isEdit={isEdit}
        editState={item}
        isOpen={isOpenItem}
        setIsOpen={setIsOpenItem}
        setShow={setShow}
        setNotificationTitle={setNotificationTitle}
        getAllItems={getAllItems}
      />

      <BranchBopItemForm
        isEdit={isEdit}
        editState={bopItem}
        isOpen={isOpenBopItem}
        setIsOpen={setIsOpenBopItem}
        setShow={setShow}
        setNotificationTitle={setNotificationTitle}
        getAllBopItems={getAllBopItems}
      />

      <BranchPlanForm
        isEdit={isEdit}
        editState={plan}
        isOpen={isOpenPlan}
        setIsOpen={setIsOpenPlan}
        setShow={setShow}
        setNotificationTitle={setNotificationTitle}
        getAllPlans={getAllPlans}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        {currentTab === tabs.items && (
          <div className="py-4">
            <ItemTable
              setShow={setShow}
              setNotificationTitle={setNotificationTitle}
              items={items}
              getAllItems={getAllItems}
              onEdit={onEditItem}
            />
          </div>
        )}
        {currentTab === tabs.bopItems && (
          <div className="py-4">
            <BopItemTable
              setShow={setShow}
              setNotificationTitle={setNotificationTitle}
              bopItems={bopItems}
              getAllBopItems={getAllBopItems}
              onEdit={onEditBopItem}
            />
          </div>
        )}
        {currentTab === tabs.plans && (
          <div className="py-4">
            <PlanTable
              setShow={setShow}
              setNotificationTitle={setNotificationTitle}
              plans={plans}
              getAllPlans={getAllPlans}
              onEdit={onEditPlan}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BranchDetail;
