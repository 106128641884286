import React from "react";
import classNames from "classnames";

import { currency } from "../../utils/currency";

interface props {
  label?: string;
  values: any[];
}

const MultipleValue: React.FC<props> = ({ label, values }) => {
  return (
    <div className="grid grid-cols-3">
      <div
        className={classNames(
          label ? "" : "hidden",
          "border-r border-r-gray-900 px-3 py-2"
        )}
      >
        <p className="font-bold text-sm">{label}</p>
      </div>
      <div
        className={classNames(
          label ? "" : "col-span-2",
          "border-r border-r-gray-900 divide-y divide-gray-900"
        )}
      >
        {values?.map((item, i) => (
          <div key={i} className="px-3 py-2">
            <p className="text-sm">
              {item.other ? `${item.name} (${item.other} g)` : item.name}
            </p>
          </div>
        ))}
      </div>
      <div className="text-right divide-y divide-gray-900">
        {values?.map((item, i) => (
          <div key={i} className="px-3 py-2">
            <p className="text-sm">
              {item.value === 0 || !item.value ? "-" : currency(item.value)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultipleValue;
