import React from "react";
import {
  setPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { auth } from "../../utils/firebase";

import Button from "../../components/Button";
import Notification from "../../components/Notification";
import useAuthentication from "../../hooks/useAuthentication";

const Login = () => {
  const primaryColor =
    "border-gray-300 text-gray-900 placeholder-gray-300 focus:ring-indigo-500 focus:border-indigo-500";
  const errorColor =
    "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500";

  const navigate = useNavigate();
  const isLoggedIn = useAuthentication();

  const [show, setShow] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] =
    React.useState<string>("");

  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  const validate = () => {
    if (email && password) {
      return true;
    } else {
      setIsError(true);
      return false;
    }
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate("/", { replace: true });
    }
  }, [isLoggedIn]);

  const onLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const isValid = validate();

    if (isValid) {
      try {
        await setPersistence(auth, browserLocalPersistence);
        await signInWithEmailAndPassword(auth, email, password);

        setNotificationMessage("Login berhasil");
      } catch (error) {
        setNotificationMessage(`Error: ${error}`);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Notification
        show={show}
        setShow={setShow}
        title={notificationMessage}
        error={isError}
      />
      <div className="mt-8 mx-auto w-5/6 sm:w-full sm:max-w-md">
        <div className="bg-default-bg sm:bg-white py-8 px-0 sm:shadow-lg sm:border sm:rounded-lg sm:px-10">
          <form className="space-y-6 mt-2">
            <div className="relative mb-2">
              <label
                htmlFor="email"
                className="text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  type="email"
                  name="email"
                  id="email"
                  onFocus={() => setNotificationMessage("")}
                  className={classNames(
                    isError && !email ? errorColor : primaryColor,
                    "block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
                  )}
                  placeholder="admin@fkdb.or.id"
                  value={email}
                  aria-invalid={isError && !email ? "true" : "false"}
                  aria-describedby={
                    isError && !email ? "email-error" : "email-valid"
                  }
                  onChange={(e) => setEmail(e.target.value)}
                />
                {isError && !email && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <svg
                      className="h-5 w-5 text-red-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                )}
              </div>
              {isError && !email && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  Email is required
                </p>
              )}
            </div>

            <div className="relative mb-10">
              <label
                htmlFor="password"
                className="text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  type="password"
                  name="password"
                  id="password"
                  onFocus={() => setNotificationMessage("")}
                  className={classNames(
                    isError && !password ? errorColor : primaryColor,
                    "block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
                  )}
                  value={password}
                  aria-invalid={isError && !password ? "true" : "false"}
                  aria-describedby={
                    isError && !password ? "password-error" : "password-valid"
                  }
                  onChange={(e) => setPassword(e.target.value)}
                />
                {isError && !password && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <svg
                      className="h-5 w-5 text-red-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                )}
              </div>
              {isError && !password && (
                <p className="mt-2 text-sm text-red-600" id="password-error">
                  Password is required
                </p>
              )}
            </div>

            <div className="text-right">
              <Button
                disabled={loading}
                loading={loading ? "true" : "false"}
                onClick={onLogin}
                label="Log in"
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
