import { FC, useEffect, useState } from "react";
import moment from "moment";

import Indonesia from "../../../utils/indonesia";
import clearState from "../../../utils/clear.state";

import { EBranchStatus, TBranch } from "../../../types/TBranch";

import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";
import TextAreaInput from "../../../components/TextAreaInput";

import services from "../../../services";

interface props {
  isEdit: boolean;
  editState: TBranch | null;
  isOpen: boolean;
  setIsOpen: Function;
  setShow: Function;
  setNotificationTitle: Function;
  getAllBranches: Function;
}

const BranchForm: FC<props> = ({
  isEdit,
  editState,
  isOpen,
  setIsOpen,
  setShow,
  setNotificationTitle,
  getAllBranches,
}) => {
  const indonesia = Indonesia as any;

  const [isError, setIsError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [provinces, setProvinces] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any[]>([]);

  const [name, setName] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [subDistrict, setSubDistrict] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [mapCoordinates, setMapCoordinates] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const regions = [
    {
      id: "Barat",
      label: "Barat",
    },
    {
      id: "Timur",
      label: "Timur",
    },
  ];

  const clearCurrentState = () => {
    const setStates = [
      setName,
      setRegion,
      setProvince,
      setCity,
      setDistrict,
      setSubDistrict,
      setAddress,
      setPhone,
      setMapCoordinates,
      setEmail,
    ];
    setIsError(false);

    clearState(setStates);
  };

  const setDefaultState = (state: TBranch | null) => {
    if (state) {
      setName(state.name);
      setRegion(state.region);
      setProvince(state.province);
      setCity(state.city);
      setDistrict(state.district);
      setSubDistrict(state.subDistrict);
      setAddress(state.address);
      setPhone(state.phone);
      setMapCoordinates(state.mapCoordinates!);
      setEmail(state.email!);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setDefaultState(editState);
    }
  }, [editState, isEdit]);

  const validate = () => {
    if (
      name &&
      region &&
      province &&
      city &&
      district &&
      subDistrict &&
      address &&
      phone
    ) {
      return true;
    } else {
      setIsError(true);
      return false;
    }
  };

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    setLoading(true);
    const isValid = validate();

    if (isValid) {
      const payload: TBranch = {
        name,
        region,
        province,
        city,
        district,
        subDistrict,
        address,
        phone,
        email,
        status: EBranchStatus.active,
        mapCoordinates,
        createdAt: editState ? editState?.createdAt : moment().unix(),
        updatedAt: moment().unix(),
      };

      let branch;

      if (isEdit) {
        branch = await services.branch.updateBranch(editState?.id!, payload);
      } else {
        branch = await services.branch.createBranch(payload);
      }

      if (branch.ok) {
        setShow(true);
        setIsOpen(false);
        setNotificationTitle(branch.message);
        clearCurrentState();
        setLoading(false);
        getAllBranches();
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const allProvinces = Object.keys(indonesia).map((el: string) => ({
      id: el,
      label: el,
    }));

    setProvinces(allProvinces);
  }, [indonesia]);

  const onProvinceChange = (id: string) => {
    setProvince(id);
    const currentCities = Object.keys(indonesia[id]).map((el: string) => ({
      id: el,
      label: el,
    }));

    setCities(currentCities);
  };

  const onCityChange = (id: string) => {
    setCity(id);
    const currentDistricts = indonesia[province][id].map((el: string) => ({
      id: el,
      label: el,
    }));

    setDistricts(currentDistricts);
  };

  const onCloseModal = (value: boolean) => {
    setIsOpen(value);
    clearCurrentState();
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={onCloseModal}
      title={`${isEdit ? "Edit" : "Tambah"} Cabang`}
    >
      <form className="flex flex-col gap-y-3 mt-5">
        <TextInput
          id="name"
          label="Nama"
          type="text"
          value={name}
          setValue={setName}
          isError={isError}
          errorMessage="Nama wajib diisi"
          required={true}
        />

        <SelectInput
          id="region"
          label="Wilayah"
          value={region}
          setValue={setRegion}
          values={regions}
          placeholder="Pilih wilayah"
          isError={isError}
          errorMessage="Wilayah wajib diisi"
          required={true}
        />

        <SelectInput
          id="province"
          label="Provinsi"
          value={province}
          setValue={onProvinceChange}
          values={provinces}
          placeholder="Pilih provinsi"
          isError={isError}
          errorMessage="Provinsi wajib diisi"
          required={true}
        />

        <SelectInput
          id="city"
          label="Kota/Kabupaten"
          value={city}
          setValue={onCityChange}
          values={cities}
          placeholder="Pilih kota/kabupaten"
          isError={isError}
          errorMessage="Kota/Kabupaten wajib diisi"
          required={true}
        />

        <SelectInput
          id="district"
          label="Kecamatan"
          value={district}
          setValue={setDistrict}
          values={districts}
          placeholder="Pilih kecamatan"
          isError={isError}
          errorMessage="Kecamtan wajib diisi"
          required={true}
        />

        <TextInput
          id="subDistrict"
          label="Desa/Kelurahan"
          type="text"
          value={subDistrict}
          setValue={setSubDistrict}
          isError={isError}
          errorMessage="Desa/Kelurahan wajib diisi"
          required={true}
        />

        <TextAreaInput
          id="address"
          label="Alamat"
          value={address}
          setValue={setAddress}
          isError={isError}
          errorMessage="Alamat wajib diisi"
          required={true}
        />

        <TextInput
          id="mapCoordinates"
          label="Google Map Koordinat"
          type="text"
          value={mapCoordinates}
          setValue={setMapCoordinates}
        />

        <TextInput
          id="phone"
          label="No. Handphone"
          type="text"
          value={phone}
          setValue={setPhone}
          isError={isError}
          errorMessage="No. Handphone wajib diisi"
          required={true}
        />

        <TextInput
          id="email"
          label="Email"
          type="text"
          value={email}
          setValue={setEmail}
          placeholder="e.g. you@fkdb.or.id"
        />

        <div className="text-right">
          <Button
            disabled={loading}
            loading={loading ? "true" : "false"}
            onClick={onSubmit}
            label={isEdit ? "Edit" : "Tambahkan"}
            type="submit"
          />
        </div>
      </form>
    </Modal>
  );
};

export default BranchForm;
