import { DocumentSnapshot } from "./TFirebase";

export type TManager = {
  id?: string;
  name: string;
  BranchId: string;
  password: string;
  adminPassword: string;
  phone: string;
  backupPhone?: string;
  status: EManagerStatus;
  createdAt: number;
  updatedAt: number;
};

export enum EManagerStatus {
  active = "ACTIVE",
  inactive = "INACTIVE",
}

export const tManagerConverter = {
  fromFirestore: (snapshot: DocumentSnapshot): TManager => {
    return { ...snapshot.data(), id: snapshot.id } as unknown as TManager;
  },
  toFirestore: (manager: TManager) => {
    return manager;
  },
};
