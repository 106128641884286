import React, { FC } from "react";
import { PlusSmIcon } from "@heroicons/react/solid";
import { UserAddIcon } from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";

import Table from "./Table";

import CircleButton from "../../components/CircleButton";
import BranchForm from "./components/BranchForm";
import Notification from "../../components/Notification";

import { TBranch } from "../../types/TBranch";

import services from "../../services";

interface props {
  search: URLSearchParams;
}

const Branch: FC<props> = ({ search }) => {
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const [notificationTitle, setNotificationTitle] = React.useState<string>("");
  const [branches, setBranches] = React.useState<TBranch[]>([]);
  const [branch, setBranch] = React.useState<TBranch | null>(null);

  const currentSearch = search.get("search");

  const onModalChange = () => {
    setIsOpen(!isOpen);
    if (isOpen === false) {
      setIsEdit(false);
    }
  };

  const getAllBranches = async () => {
    const data = await services.branch.getBranches();

    if (data.ok === true) {
      setBranches(data.data);
    }
  };

  const searchBranchesByName = async (value: string) => {
    const data = await services.branch.getBranches();

    if (data.ok === true) {
      const result = data.data.filter((el: TBranch) =>
        el.name.toLowerCase().includes(value.toLowerCase())
      );

      setBranches(result);
    }
  };

  const getBranch = async (id: string) => {
    const data = await services.branch.getBranch(id);

    if (data.ok) {
      setBranch(data.data);
    }
  };

  const onEdit = (id: string) => {
    setIsEdit(true);
    setIsOpen(true);
    getBranch(id);
  };

  React.useEffect(() => {
    if (currentSearch) {
      searchBranchesByName(currentSearch);
    } else {
      getAllBranches();
    }
  }, [currentSearch]);

  return (
    <div>
      <Notification show={show} setShow={setShow} title={notificationTitle} />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex justify-between">
        <div className="flex gap-x-3">
          <h1 className="text-2xl font-semibold text-gray-900">Cabang</h1>
          <CircleButton
            onClick={onModalChange}
            icon={<PlusSmIcon className="h-5 w-5" aria-hidden="true" />}
          />
        </div>
        <div className="flex gap-x-3">
          <NavLink to="/branch/manager">
            <CircleButton
              onClick={onModalChange}
              icon={<UserAddIcon className="h-5 w-5" aria-hidden="true" />}
            />
          </NavLink>
        </div>
      </div>

      <BranchForm
        isEdit={isEdit}
        editState={branch}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setShow={setShow}
        setNotificationTitle={setNotificationTitle}
        getAllBranches={getAllBranches}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4">
          <Table
            setShow={setShow}
            setNotificationTitle={setNotificationTitle}
            branches={branches}
            getAllBranches={
              currentSearch
                ? () => searchBranchesByName(currentSearch)
                : getAllBranches
            }
            onEdit={onEdit}
          />
        </div>
      </div>
    </div>
  );
};

export default Branch;
