import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { firestore } from "../utils/firebase";
import { success, fail, notFound } from "../utils/status.message";

import { TPlan, tPlanConverter } from "../types/TPlan";

const planRef = collection(firestore, "plans");

const getPlans = async () => {
  try {
    const querySnapshot = await getDocs(planRef.withConverter(tPlanConverter));
    const data = querySnapshot.docs.map((el) => el.data());

    return success("Berhasil mendapatkan semua data rencana", data);
  } catch (error) {
    return fail();
  }
};

const getPlansByBranchId = async (id: string) => {
  try {
    const querySnapshot = await getDocs(
      query(planRef.withConverter(tPlanConverter), where("BranchId", "==", id))
    );
    const data = querySnapshot.docs.map((el) => el.data());

    return success("Berhasil mendapatkan semua data rencana", data);
  } catch (error) {
    return fail();
  }
};

const getPlan = async (id: string) => {
  try {
    const docRef = doc(planRef, id);
    const docSnap = await getDoc(docRef.withConverter(tPlanConverter));

    if (docSnap.exists()) {
      return success("Berhasil mendapatkan data rencana", docSnap.data());
    } else {
      return notFound();
    }
  } catch (error) {
    return fail();
  }
};

const createPlan = async (item: TPlan) => {
  try {
    const docRef = doc(planRef);
    await setDoc(docRef, item);

    return success("Berhasil membuat rencana", item);
  } catch (error) {
    return fail();
  }
};

const updatePlan = async (id: string, item: TPlan) => {
  try {
    const docRef = doc(firestore, "plans", id);
    await updateDoc(docRef, item);

    return success("Berhasil memperbarui rencana", item);
  } catch (error) {
    return fail();
  }
};

const deletePlan = async (id: string) => {
  try {
    const docRef = doc(firestore, "plans", id);
    await deleteDoc(docRef);

    return success("Berhasil menghapus rencana", null);
  } catch (error) {
    return fail();
  }
};

const plan = {
  getPlans,
  getPlansByBranchId,
  getPlan,
  createPlan,
  updatePlan,
  deletePlan,
};

export default plan;
