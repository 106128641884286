import React, { FC } from "react";

import BranchManagerTable from "../../components/BranchManagerTable";

import { TBranch } from "../../types/TBranch";
import { TManager } from "../../types/TManager";

interface props {
  setShow: Function;
  setNotificationTitle: Function;
  managers: TManager[];
  branches: TBranch[];
  getAllManagers: Function;
  onEdit: Function;
}

const Table: FC<props> = ({
  setShow,
  setNotificationTitle,
  managers,
  branches,
  getAllManagers,
  onEdit,
}) => {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <BranchManagerTable
              data={managers}
              branches={branches}
              setShow={setShow}
              setNotificationTitle={setNotificationTitle}
              refetchData={getAllManagers}
              onEdit={onEdit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
