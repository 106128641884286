import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";

import { firestore } from "../utils/firebase";
import { success, fail, notFound } from "../utils/status.message";

import { TBranch, tBranchConverter } from "../types/TBranch";

const branchRef = collection(firestore, "branches");
const managerRef = collection(firestore, "managers");
const itemRef = collection(firestore, "items");
const bopItemRef = collection(firestore, "bopItems");
const planRef = collection(firestore, "plans");

const getBranches = async () => {
  try {
    const querySnapshot = await getDocs(
      branchRef.withConverter(tBranchConverter)
    );

    const data = querySnapshot.docs.map((el) => el.data());

    return success("Berhasil mendapatkan semua data cabang", data);
  } catch (error) {
    return fail();
  }
};

const getBranch = async (id: string) => {
  try {
    const docRef = doc(branchRef, id);
    const docSnap = await getDoc(docRef.withConverter(tBranchConverter));

    if (docSnap.exists()) {
      return success("Berhasil mendapatkan data cabang", docSnap.data());
    } else {
      return notFound();
    }
  } catch (error) {
    return fail();
  }
};

const createBranch = async (branch: TBranch) => {
  try {
    const docRef = doc(branchRef);
    await setDoc(docRef, branch);

    return success("Berhasil membuat cabang", branch);
  } catch (error) {
    return fail();
  }
};

const updateBranch = async (id: string, branch: TBranch) => {
  try {
    const docRef = doc(firestore, "branches", id);
    await updateDoc(docRef, branch);

    return success("Berhasil memperbarui cabang", branch);
  } catch (error) {
    return fail();
  }
};

const deleteBranch = async (id: string) => {
  try {
    const batch = writeBatch(firestore);

    const docRef = doc(firestore, "branches", id);
    batch.delete(docRef);

    const managersSnapshot = await getDocs(
      query(managerRef, where("BranchId", "==", id))
    );
    managersSnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    const itemsSnapshot = await getDocs(
      query(itemRef, where("BranchId", "==", id))
    );
    itemsSnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    const bopItemsSnapshot = await getDocs(
      query(bopItemRef, where("BranchId", "==", id))
    );
    bopItemsSnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    const plansSnapshot = await getDocs(
      query(planRef, where("BranchId", "==", id))
    );
    plansSnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });

    batch.commit();

    return success("Berhasil menghapus cabang", null);
  } catch (error) {
    return fail();
  }
};

const business = {
  getBranches,
  getBranch,
  createBranch,
  updateBranch,
  deleteBranch,
};

export default business;
