import { FC, useEffect, useState } from "react";
import moment from "moment";

import clearState from "../../../utils/clear.state";

import { EManagerStatus, TManager } from "../../../types/TManager";
import { TBranch } from "../../../types/TBranch";

import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";

import services from "../../../services";

interface props {
  isEdit: boolean;
  editState: TManager | null;
  isOpen: boolean;
  setIsOpen: Function;
  setShow: Function;
  branches: TBranch[];
  setNotificationTitle: Function;
  getAllManagers: Function;
}

const BranchManagerForm: FC<props> = ({
  isOpen,
  setIsOpen,
  isEdit,
  editState,
  setShow,
  branches,
  setNotificationTitle,
  getAllManagers,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [branchId, setBranchId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [adminPassword, setAdminPassword] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [backupPhone, setBackupPhone] = useState<string>("");

  const branchOptions = branches.map((el) => ({ id: el.id, label: el.name }));

  const validate = () => {
    if (name && branchId && phone) {
      return true;
    } else {
      setIsError(true);
      return false;
    }
  };

  const clearCurrentState = () => {
    const setStates = [
      setName,
      setBranchId,
      setPassword,
      setAdminPassword,
      setPhone,
      setBackupPhone,
    ];
    setIsError(false);

    clearState(setStates);
  };

  const setDefaultState = (state: TManager | null) => {
    if (state) {
      setName(state.name);
      setBranchId(state.BranchId);
      setPassword(state.password);
      setAdminPassword(state.adminPassword);
      setPhone(state.phone);
      setBackupPhone(state.backupPhone!);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setDefaultState(editState);
    }
  }, [editState, isEdit]);

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    setLoading(true);
    const isValid = validate();

    if (isValid) {
      const payload: TManager = {
        name,
        BranchId: branchId,
        password,
        adminPassword,
        phone,
        backupPhone,
        status: EManagerStatus.active,
        createdAt: editState ? editState?.createdAt : moment().unix(),
        updatedAt: moment().unix(),
      };

      let manager;

      if (isEdit) {
        manager = await services.manager.updateManager(editState?.id!, payload);
      } else {
        manager = await services.manager.createManager(payload);
      }

      if (manager.ok) {
        setShow(true);
        setIsOpen(false);
        setNotificationTitle(manager.message);
        clearCurrentState();
        setLoading(false);
        getAllManagers();
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const onCloseModal = (value: boolean) => {
    setIsOpen(value);
    clearCurrentState();
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={onCloseModal}
      title={`${isEdit ? "Edit" : "Tambah"} Manager Cabang`}
    >
      <form className="flex flex-col gap-y-3 mt-5">
        <TextInput
          id="name"
          label="Nama"
          type="text"
          value={name}
          setValue={setName}
          isError={isError}
          errorMessage="Nama wajib diisi"
          required={true}
        />

        <TextInput
          id="phone"
          label="No. Hp"
          type="text"
          value={phone}
          setValue={setPhone}
          isError={isError}
          errorMessage="No. Hp wajib diisi"
          required={true}
        />

        <TextInput
          id="backupPhone"
          label="No. Hp Cadangan"
          type="text"
          value={backupPhone}
          setValue={setBackupPhone}
        />

        <SelectInput
          id="branchId"
          label="Cabang"
          value={branchId}
          setValue={setBranchId}
          values={branchOptions}
          placeholder="Pilih cabang"
          isError={isError}
          errorMessage="Cabang wajib diisi"
          required={true}
        />

        <TextInput
          id="password"
          label="Password"
          type="password"
          value={password}
          setValue={setPassword}
          isError={isError}
          errorMessage="Password wajib diisi"
          required={true}
        />

        <TextInput
          id="adminPassword"
          label="Admin Password"
          type="password"
          value={adminPassword}
          setValue={setAdminPassword}
          isError={isError}
          errorMessage="Admin password wajib diisi"
          required={true}
        />

        <div className="text-right">
          <Button
            disabled={loading}
            loading={loading ? "true" : "false"}
            onClick={onSubmit}
            label={isEdit ? "Edit" : "Tambahkan"}
            type="submit"
          />
        </div>
      </form>
    </Modal>
  );
};

export default BranchManagerForm;
