import { FC } from "react";
import * as XLSX from "xlsx";
import moment from "moment";

import { generateReportTemplate } from "../utils/generate-report-template";

interface props {
  branchName?: string;
  branchId: string;
  listItem: string[][];
  listHppItem: string[][];
  listBopItem: string[][];
}

const DownloadReportTemplate: FC<props> = ({
  branchName,
  branchId,
  listItem,
  listHppItem,
  listBopItem,
}) => {
  const currentDate = moment(new Date()).format("DDMMYYYY");
  const fileName = `${branchName} - ${currentDate}`;

  const aoaData = generateReportTemplate(
    branchId,
    listItem,
    listHppItem,
    listBopItem
  );

  const onGenerateExcel = () => {
    const workSheet = XLSX.utils.aoa_to_sheet(aoaData);
    const workBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workBook, workSheet);
    XLSX.writeFile(workBook, `${fileName}.xlsx`);
  };

  return (
    <button
      onClick={onGenerateExcel}
      className="w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      Download Template Laporan
    </button>
  );
};

export default DownloadReportTemplate;
