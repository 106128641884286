import { FC, ReactElement } from "react";

interface props {
  icon: ReactElement;
  [x: string]: any;
}

const CircleButton: FC<props> = ({ icon, ...rest }) => {
  return (
    <button
      {...rest}
      type="button"
      className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      {icon}
    </button>
  );
};

export default CircleButton;
