const sumItems = (items: any, day?: any) => {
  const result = Object.values(
    items.reduce((acc: any, el: any) => {
      acc[el.id] = {
        ...el,
        value: (acc[el.id] ? acc[el.id].value : 0) + el.value,
      };
      return acc;
    }, {})
  );

  return result.map((el: any) => {
    if (el.name === "Harga Kedelai Rp/Kg") {
      el.value = (el.value / day).toFixed(0);
    }

    return el;
  });
};

export const sumData = (payload: any, day: Number) => {
  const result = payload.map((el: any) => {
    return {
      totalProduction: Number(el.totalProduction) || 0,
      otherIncome: Number(el.otherIncome) || 0,
      revenueRealization: Number(el.revenueRealization) || 0,
      productionItems: el.productionItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      previouslySavedItems: el.previouslySavedItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      soldItems: el.soldItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      receivableItems: el.receivableItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      returnItems: el.returnItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      writeOffItems: el.writeOffItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      bopItems: el.bopItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      hppItems: el.hppItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
    };
  });

  if (payload.length !== 0) {
    const sumResult = result.reduce((el: any, value: any) => {
      for (const [valueName, valueCount] of Object.entries(value)) {
        if (
          valueName === "totalProduction" ||
          valueName === "otherIncome" ||
          valueName === "revenueRealization"
        ) {
          if (!el[valueName]) {
            el[valueName] = 0;
          }

          el[valueName] += valueCount;
        } else {
          if (!el[valueName]) {
            el[valueName] = [];
          }

          el[valueName] = el[valueName].concat(valueCount);
        }
      }

      return el;
    }, {});

    sumResult["date"] = payload[0].date;
    sumResult["productionItems"] = sumItems(sumResult.productionItems);
    sumResult["previouslySavedItems"] = sumItems(
      sumResult.previouslySavedItems
    );
    sumResult["soldItems"] = sumItems(sumResult.soldItems);
    sumResult["receivableItems"] = sumItems(sumResult.receivableItems);
    sumResult["returnItems"] = sumItems(sumResult.returnItems);
    sumResult["writeOffItems"] = sumItems(sumResult.writeOffItems);
    sumResult["bopItems"] = sumItems(sumResult.bopItems);
    sumResult["hppItems"] = sumItems(sumResult.hppItems, day);

    return sumResult;
  } else {
    return null;
  }
};

export const sumMonthData = (payload: any, day: Number) => {
  const result = payload.map((el: any) => {
    return {
      totalProduction: Number(el.totalProduction) || 0,
      otherIncome: Number(el.otherIncome) || 0,
      revenueRealization: Number(el.revenueRealization) || 0,
      productionItems: el.productionItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      previouslySavedItems: el.previouslySavedItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      soldItems: el.soldItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      receivableItems: el.receivableItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      returnItems: el.returnItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      writeOffItems: el.writeOffItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      bopItems: el.bopItems.map((el: any) => ({
        ...el,
        value: Number(el.value) || 0,
      })),
      hppItems: el.hppItems.map((hpp: any, i: number) => {
        if (hpp.name === "Biaya Kedelai") {
          return {
            ...hpp,
            value:
              Number(el?.totalProduction) * Number(el?.hppItems[i + 1]?.value),
          };
        } else {
          return {
            ...hpp,
            value: Number(hpp.value) || 0,
          };
        }
      }),
    };
  });

  if (payload.length !== 0) {
    const sumResult = result.reduce((el: any, value: any) => {
      for (const [valueName, valueCount] of Object.entries(value)) {
        if (
          valueName === "totalProduction" ||
          valueName === "otherIncome" ||
          valueName === "revenueRealization"
        ) {
          if (!el[valueName]) {
            el[valueName] = 0;
          }

          el[valueName] += valueCount;
        } else {
          if (!el[valueName]) {
            el[valueName] = [];
          }

          el[valueName] = el[valueName].concat(valueCount);
        }
      }

      return el;
    }, {});

    sumResult["date"] = payload[0].date;
    sumResult["productionItems"] = sumItems(sumResult.productionItems);
    sumResult["previouslySavedItems"] = sumItems(
      sumResult.previouslySavedItems
    );
    sumResult["soldItems"] = sumItems(sumResult.soldItems);
    sumResult["receivableItems"] = sumItems(sumResult.receivableItems);
    sumResult["returnItems"] = sumItems(sumResult.returnItems);
    sumResult["writeOffItems"] = sumItems(sumResult.writeOffItems);
    sumResult["bopItems"] = sumItems(sumResult.bopItems);
    sumResult["hppItems"] = sumItems(sumResult.hppItems, day);
    const totalHpp = sumResult["hppItems"]
      ?.map((el: any, i: number) => {
        if (i === 1) {
          return 0;
        } else {
          return Number(el.value);
        }
      })
      ?.reduce((a: number, b: number) => a + b, 0);

    sumResult["totalHpp"] = totalHpp;
    sumResult["totalGrossProfit"] =
      Number(sumResult.otherIncome) +
      Number(sumResult.revenueRealization) -
      totalHpp;

    return sumResult;
  } else {
    return null;
  }
};
