export const currency = (number: number | string | undefined): string => {
  if (typeof number === "string") {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else if (typeof number === "number") {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return "";
  }
};
export const unformat = (val: string | undefined): string =>
  !!val ? val.replace(/\./g, "") : "0";
