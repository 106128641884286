import { FC } from "react";
import classNames from "classnames";

interface props {
  id: string;
  placeholder?: string;
  label: string;
  value: boolean;
  setValue: Function;
  isError?: boolean;
  errorMessage?: string;
  required?: boolean;
}

const CheckboxInput: FC<props> = ({
  id,
  placeholder,
  label,
  value,
  setValue,
  isError,
  errorMessage,
  required = false,
}) => {
  if (!required) {
    return (
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={id}
            aria-describedby={`${id}-description`}
            name={id}
            type="checkbox"
            checked={value}
            onChange={(e) => setValue(e.target.checked)}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={id} className="font-medium text-gray-700">
            {label}
          </label>
          {placeholder && (
            <p id={`${id}-description`} className="text-gray-500">
              {placeholder}
            </p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          id={id}
          aria-describedby={`${id}-description`}
          name={id}
          type="checkbox"
          checked={value}
          onChange={(e) => setValue(e.target.checked)}
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className="font-medium text-gray-700">
          {label}
        </label>
        {placeholder && (
          <p id={`${id}-description`} className="text-gray-500">
            {placeholder}
          </p>
        )}
      </div>
      {isError && !value && (
        <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
      )}
    </div>
  );
};

export default CheckboxInput;
