import React, { FC } from "react";
import classNames from "classnames";
import { AdjustmentsIcon } from "@heroicons/react/solid";
import { CubeIcon, PresentationChartLineIcon } from "@heroicons/react/outline";

interface props {
  currentTab: string;
  setCurrentTab: Function;
}

const Tab: FC<props> = ({ currentTab, setCurrentTab }) => {
  const tabs = [
    {
      name: "Items",
      onClick: () => {
        onSelectTab("Items");
      },
      icon: CubeIcon,
      current: false,
    },
    {
      name: "Bop Items",
      onClick: () => {
        onSelectTab("Bop Items");
      },
      icon: AdjustmentsIcon,
      current: false,
    },
    {
      name: "Plans",
      onClick: () => {
        onSelectTab("Plans");
      },
      icon: PresentationChartLineIcon,
      current: false,
    },
  ];

  React.useEffect(() => {
    if (!currentTab) {
      const branchDetailTab = localStorage.getItem("branchDetailTab");

      if (branchDetailTab) {
        setCurrentTab(branchDetailTab);
      } else {
        setCurrentTab("Items");
        localStorage.setItem("branchDetailTab", "Items");
      }
    }
  }, [currentTab]);

  const onSelectTab = (value: string) => {
    setCurrentTab(value);
    localStorage.setItem("branchDetailTab", value);
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          value={currentTab}
          onChange={(e) => onSelectTab(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={tab.onClick}
                className={classNames(
                  tab.name === currentTab
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "cursor-pointer group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.name === currentTab ? "page" : undefined}
              >
                <tab.icon
                  className={classNames(
                    tab.name === currentTab
                      ? "text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5"
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tab;
