import React from "react";
import classNames from "classnames";

interface props {
  label: string;
  value: any;
  className?: string;
  background?: string;
}

const OneValue: React.FC<props> = ({
  label,
  value,
  className = "text-sm",
  background = "",
}) => {
  return (
    <div className={classNames(background, "grid grid-cols-3")}>
      <div
        className={classNames(
          value ? "col-span-2 border-r border-r-gray-900" : "col-span-3",
          "px-3 py-2"
        )}
      >
        <p className={classNames(className, "font-bold")}>{label}</p>
      </div>
      <div
        className={classNames(value ? "" : "hidden", "px-3 py-2 text-right")}
      >
        <p className="text-sm">{value}</p>
      </div>
    </div>
  );
};

export default OneValue;
