import React from "react";
import moment from "moment";

import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";

import { TReport } from "../../types/TReport";
import { TBranch } from "../../types/TBranch";
import { TBopItem } from "../../types/TBopItem";

import services from "../../services";

import MultipleValue from "./MultipleValue";
import OneValue from "./OneValue";

import { currency } from "../../utils/currency";
import { sumData, sumMonthData } from "../../utils/sum.data";

import { useCustomContext } from "../../hooks/useCustomContext";

const Recap = () => {
  const context = useCustomContext();

  const defaultBranchId = context.setting?.defaultBranchId;

  const [branchId, setBranchId] = React.useState<string>("");
  const [month, setMonth] = React.useState<string>(
    moment(new Date()).format("YYYY-MM")
  );
  const [type, setType] = React.useState<string>("recap");

  const [branches, setBranches] = React.useState<TBranch[]>([]);
  const [reports, setReports] = React.useState<TReport[]>([]);
  const [bopItems, setBopItems] = React.useState<TBopItem[]>([]);

  const getAllBranches = async () => {
    const data = await services.branch.getBranches();

    if (data.ok === true) {
      setBranches(data.data);
    }
  };

  const getAllBopItems = async (id: string) => {
    const data = await services.bopItem.getBopItemsByBranchId(id);

    if (data.ok) {
      setBopItems(data.data);
    }
  };

  const lastday = (y: number, m: number) => {
    return new Date(y, m, 0).getDate();
  };

  const getAllReports = async (id: string, currentMonth: any) => {
    const splitMonth = currentMonth?.split("-");
    const year = Number(splitMonth?.[0]);
    const month = Number(splitMonth?.[1]);
    const startDate = `${currentMonth}-01`;
    const endDate = `${currentMonth}-${lastday(year, month)}`;

    const start = moment(new Date(startDate)).startOf("day").unix();
    const end = moment(new Date(endDate)).endOf("day").unix();

    const data = await services.report.getReportsByBranchId(id, start, end);

    if (data.ok === true) {
      setReports(data.data);
    }
  };

  React.useEffect(() => {
    getAllBranches();
    getAllBopItems(branchId);
    getAllReports(branchId, month);
  }, [branchId, month]);

  React.useEffect(() => {
    if (!branchId) {
      setBranchId(defaultBranchId || branches?.[0]?.id!);
    }
  }, [branches, branchId, defaultBranchId]);

  const sumTempehValues = (data: any[]) => {
    const total = data
      ?.map((el) => Number(el.value) * (Number(el.other) / 1000))
      ?.reduce((a, b) => a + b, 0);

    return total;
  };

  const sumHppItemValues = (data: any[], totalProduction: any) => {
    const total = data
      ?.map((el, i) => {
        if (el.name === "Biaya Kedelai") {
          return Number(totalProduction) * Number(data[i + 1].value);
        }
        if (i === 1) {
          return 0;
        } else {
          return Number(el.value);
        }
      })
      ?.reduce((a, b) => a + b, 0);

    return total;
  };

  const sumBopValues = (data: any[]) => {
    const total = data
      ?.map((el) => Number(el.value))
      ?.reduce((a, b) => a + b, 0);

    return total;
  };

  const uptrendSoybeanVolum = (data: any[], totalProduction: any) => {
    const total = (sumTempehValues(data) / Number(totalProduction)) * 100 - 100;

    return total;
  };

  const branchOptions = branches.map((el) => ({ id: el.id, label: el.name }));

  const splitMonth = month?.split("-");
  const y = Number(splitMonth?.[0]);
  const m = Number(splitMonth?.[1]);
  const endDay = lastday(y, m);
  const currentMonth = moment(new Date(month + "-01")).format("MMMM");

  const weekOne = reports
    .map((el) => {
      const startDate = `${month}-1`;
      const endDate = `${month}-7`;

      const start = moment(new Date(startDate)).startOf("day").unix();
      const end = moment(new Date(endDate)).endOf("day").unix();
      if (el.date >= start && el.date <= end) {
        const day = Number(moment.unix(el.date).format("DD"));

        if (day === 7) {
          return {
            ...el,
            date: ["Week 1", `${currentMonth} (1 - 7)`],
          };
        } else {
          return {
            ...el,
            previouslySavedItems: el.previouslySavedItems.map((item) => ({
              ...item,
              value: 0,
            })),
            receivableItems: el.receivableItems.map((item) => ({
              ...item,
              value: 0,
            })),
            writeOffItems: el.writeOffItems.map((item) => ({
              ...item,
              value: 0,
            })),
            date: ["Week 1", `${currentMonth} (1 - 7)`],
          };
        }
      }

      return null;
    })
    .filter((el) => el !== null);

  const weekTwo = reports
    .map((el) => {
      const startDate = `${month}-8`;
      const endDate = `${month}-14`;

      const start = moment(new Date(startDate)).startOf("day").unix();
      const end = moment(new Date(endDate)).endOf("day").unix();
      if (el.date >= start && el.date <= end) {
        const day = Number(moment.unix(el.date).format("DD"));

        if (day === 14) {
          return {
            ...el,
            date: ["Week 2", `${currentMonth} (8 - 14)`],
          };
        } else {
          return {
            ...el,
            previouslySavedItems: el.previouslySavedItems.map((item) => ({
              ...item,
              value: 0,
            })),
            receivableItems: el.receivableItems.map((item) => ({
              ...item,
              value: 0,
            })),
            writeOffItems: el.writeOffItems.map((item) => ({
              ...item,
              value: 0,
            })),
            date: ["Week 2", `${currentMonth} (8 - 14)`],
          };
        }
      }

      return null;
    })
    .filter((el) => el !== null);

  const weekThree = reports
    .map((el) => {
      const startDate = `${month}-15`;
      const endDate = `${month}-21`;

      const start = moment(new Date(startDate)).startOf("day").unix();
      const end = moment(new Date(endDate)).endOf("day").unix();
      if (el.date >= start && el.date <= end) {
        const day = Number(moment.unix(el.date).format("DD"));

        if (day === 21) {
          return {
            ...el,
            date: ["Week 3", `${currentMonth} (15 - 21)`],
          };
        } else {
          return {
            ...el,
            previouslySavedItems: el.previouslySavedItems.map((item) => ({
              ...item,
              value: 0,
            })),
            receivableItems: el.receivableItems.map((item) => ({
              ...item,
              value: 0,
            })),
            writeOffItems: el.writeOffItems.map((item) => ({
              ...item,
              value: 0,
            })),
            date: ["Week 3", `${currentMonth} (15 - 21)`],
          };
        }
      }

      return null;
    })
    .filter((el) => el !== null);

  const weekFour = reports
    .map((el) => {
      const startDate = `${month}-22`;
      const endDate = `${month}-${endDay}`;

      const start = moment(new Date(startDate)).startOf("day").unix();
      const end = moment(new Date(endDate)).endOf("day").unix();
      if (el.date >= start && el.date <= end) {
        const day = Number(moment.unix(el.date).format("DD"));

        if (day === Number(endDay)) {
          return {
            ...el,
            date: ["Week 4", `${currentMonth} (22 - ${endDay})`],
          };
        } else {
          return {
            ...el,
            previouslySavedItems: el.previouslySavedItems.map((item) => ({
              ...item,
              value: 0,
            })),
            receivableItems: el.receivableItems.map((item) => ({
              ...item,
              value: 0,
            })),
            writeOffItems: el.writeOffItems.map((item) => ({
              ...item,
              value: 0,
            })),
            date: ["Week 4", `${currentMonth} (22 - ${endDay})`],
          };
        }
      }

      return null;
    })
    .filter((el) => el !== null);

  const weekOneLength = weekOne.filter((el) => el?.totalProduction).length;
  const weekTwoLength = weekTwo.filter((el) => el?.totalProduction).length;
  const weekThreeLength = weekThree.filter((el) => el?.totalProduction).length;
  const weekFourLength = weekFour.filter((el) => el?.totalProduction).length;

  const lenghts = [
    weekOneLength,
    weekTwoLength,
    weekThreeLength,
    weekFourLength,
  ];
  const realMonthLength =
    weekOneLength + weekTwoLength + weekThreeLength + weekFourLength;

  const sumWeekOne = sumData(weekOne, 7);
  const sumWeekTwo = sumData(weekTwo, 7);
  const sumWeekThree = sumData(weekThree, 7);
  const sumWeekFour = sumData(weekFour, endDay - 21);

  const allWeekResult = [
    sumWeekOne,
    sumWeekTwo,
    sumWeekThree,
    sumWeekFour,
  ].filter((el) => el !== null);

  const selectedMonth = allWeekResult
    .map((el, i) => {
      if (i === 3) {
        return {
          ...el,
          date: ["Monthly", `${currentMonth} (1 - ${endDay})`],
        };
      } else {
        return {
          ...el,
          previouslySavedItems: el.previouslySavedItems.map((item: any) => ({
            ...item,
            value: 0,
          })),
          receivableItems: el.receivableItems.map((item: any) => ({
            ...item,
            value: 0,
          })),
          writeOffItems: el.writeOffItems.map((item: any) => ({
            ...item,
            value: 0,
          })),
          date: ["Monthly", `${currentMonth} (1 - ${endDay})`],
        };
      }
    })
    .filter((el) => el !== null);

  const result = sumMonthData(selectedMonth, 4);

  const typeOptions = [
    {
      id: "recap",
      label: "Rekap",
    },
    {
      id: "detail",
      label: "Detail",
    },
  ];

  const recap = allWeekResult.map((allWeek, i) => {
    const bop1Items: TBopItem[] = [];
    const bop2Items: TBopItem[] = [];

    allWeek.bopItems.forEach((el: TBopItem) => {
      const currentBop = bopItems.find((item) => item.name === el.name);
      if (currentBop?.isEducationBudget) {
        bop2Items.push(el);
      } else {
        bop1Items.push(el);
      }
    });

    return {
      id: i,
      date: allWeek.date,
      production: Math.round(allWeek?.totalProduction / lenghts[i]),
      omzet: Math.round(
        (Number(allWeek?.otherIncome) +
          Number(allWeek?.revenueRealization) -
          sumHppItemValues(allWeek?.hppItems, allWeek?.totalProduction)) /
          lenghts[i]
      ),
      hpp: Math.round(
        sumHppItemValues(allWeek?.hppItems, allWeek?.totalProduction) /
          lenghts[i]
      ),
      bop1: Math.round(sumBopValues(bop1Items) / lenghts[i]),
      bop2: Math.round(sumBopValues(bop2Items) / lenghts[i]),
      profit: Math.round(
        (Number(allWeek?.otherIncome) +
          Number(allWeek?.revenueRealization) -
          sumHppItemValues(allWeek?.hppItems, allWeek?.totalProduction) -
          sumBopValues(allWeek?.bopItems)) /
          lenghts[i]
      ),
    };
  });

  const bop1Items: TBopItem[] = [];
  const bop2Items: TBopItem[] = [];

  result?.bopItems?.forEach((el: TBopItem) => {
    const currentBop = result?.bopItems?.find(
      (item: any) => item.name === el.name
    );
    if (currentBop?.isEducationBudget) {
      bop2Items.push(el);
    } else {
      bop1Items.push(el);
    }
  });

  const recapSelectedMonth = [
    {
      id: 4,
      date: result?.date,
      production: Math.round(result?.totalProduction / realMonthLength),
      omzet: Math.round(result?.totalGrossProfit / realMonthLength),
      hpp: Math.round(result?.totalHpp / realMonthLength),
      bop1: Math.round(sumBopValues(bop1Items) / realMonthLength),
      bop2: Math.round(sumBopValues(bop2Items) / realMonthLength),
      profit: Math.round(
        (Number(result?.otherIncome) +
          Number(result?.revenueRealization) -
          sumHppItemValues(result?.hppItems, result?.totalProduction) -
          sumBopValues(result?.bopItems)) /
          realMonthLength
      ),
    },
  ];

  return (
    <div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex flex-col sm:flex-row sm:justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">{`${
          type === "detail" ? "Detail " : ""
        }Rekap Laporan Laba/Rugi`}</h1>
        <div className="flex flex-col sm:flex-row gap-x-2">
          <SelectInput
            id="type"
            value={type}
            setValue={setType}
            values={typeOptions}
            placeholder="Tipe"
          />
          <TextInput
            type="month"
            id="month"
            value={month}
            setValue={setMonth}
          />
          <SelectInput
            id="branchId"
            value={branchId}
            setValue={setBranchId}
            values={branchOptions}
            placeholder="Cabang"
          />
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <div className="py-4 grid grid-cols-1 md:grid-cols-2 gap-3">
          {type === "recap" ? (
            <>
              {recap
                .concat(recapSelectedMonth)
                ?.filter((el) => el.production)
                ?.map((el, i) => (
                  <div
                    key={i}
                    className="border border-gray-900 rounded-lg divide-y divide-gray-900"
                  >
                    <OneValue
                      label={el?.date?.[0]}
                      value={el?.date?.[1]}
                      background="bg-indigo-400 rounded-t-lg font-bold"
                    />
                    <OneValue
                      label="PRODUKSI (Kg/hari)"
                      value={currency(el?.production)}
                    />
                    <OneValue
                      label="OMZET (Rp/hari)"
                      value={currency(el?.omzet)}
                    />
                    <OneValue label="HPP (Rp/hari)" value={currency(el?.hpp)} />
                    <OneValue
                      label="BOP 1 (Rp/hari)"
                      value={currency(el?.bop1)}
                    />
                    <OneValue
                      label="BOP 2 (Rp/hari)"
                      value={currency(el?.bop2)}
                    />
                    <OneValue
                      label="PROFIT (Rp/hari)"
                      value={currency(el?.profit)}
                    />
                  </div>
                ))}
            </>
          ) : (
            <>
              {/* Detail section */}
              {allWeekResult?.map((el, i) => (
                <div
                  key={i}
                  className="border border-gray-900 rounded-lg divide-y divide-gray-900"
                >
                  <OneValue
                    label={el.date[0]}
                    value={el.date[1]}
                    background="bg-indigo-400 rounded-t-lg font-bold"
                  />
                  <OneValue
                    label="PRODUKSI (KG)"
                    value={currency(el.totalProduction)}
                  />
                  <OneValue
                    label="Volume Produksi (Kg)"
                    value={currency(
                      sumTempehValues(el.productionItems).toFixed(0)
                    )}
                    className="text-xs"
                  />
                  <MultipleValue
                    label="PRODUKSI (BTG)"
                    values={el.productionItems}
                  />
                  <OneValue
                    label="%kenaikan volume kedelai"
                    value={`${uptrendSoybeanVolum(
                      el.productionItems,
                      el.totalProduction
                    ).toFixed(0)}%`}
                    className="text-xs"
                  />
                  <MultipleValue
                    label="TERSIMPAN SEBELUMNYA"
                    values={el.previouslySavedItems}
                  />
                  <OneValue label="PENJUALAN" value="" />
                  <MultipleValue label="Terjual" values={el.soldItems} />
                  <MultipleValue label="Piutang" values={el.receivableItems} />
                  <MultipleValue label="BS" values={el.returnItems} />
                  <MultipleValue label="Afkir" values={el.writeOffItems} />
                  <OneValue
                    label="Pendapatan Lain"
                    value={
                      el.otherIncome ? `Rp${currency(el.otherIncome)}` : "-"
                    }
                    className="text-xs"
                  />
                  <OneValue
                    label="Realisasi Pendapatan"
                    value={`Rp${currency(el.revenueRealization)}`}
                    className="text-xs"
                  />
                  <OneValue
                    label="Pendapatan Total"
                    value={`Rp${currency(
                      Number(el.otherIncome) + Number(el.revenueRealization)
                    )}`}
                  />
                  <OneValue label="HPP" value="" />
                  <MultipleValue
                    values={el.hppItems.map((hpp: any, i: number) => {
                      if (hpp.name === "Biaya Kedelai") {
                        return {
                          ...hpp,
                          value:
                            Number(el.totalProduction) *
                            Number(el.hppItems[i + 1].value),
                        };
                      } else {
                        return hpp;
                      }
                    })}
                  />
                  <OneValue
                    label="TOTAL HPP"
                    value={`Rp${currency(
                      sumHppItemValues(el.hppItems, el.totalProduction)
                    )}`}
                  />
                  <OneValue
                    label="LABA KOTOR"
                    value={`Rp${currency(
                      Number(el.otherIncome) +
                        Number(el.revenueRealization) -
                        sumHppItemValues(el.hppItems, el.totalProduction)
                    )}`}
                  />
                  <OneValue label="BIAYA OPERASIONAL" value="" />
                  <MultipleValue values={el.bopItems} />
                  <OneValue
                    label="JUMLAH BOP"
                    value={`Rp${currency(sumBopValues(el.bopItems))}`}
                  />
                  <OneValue
                    label="LABA BERSIH"
                    value={`Rp${currency(
                      Number(el.otherIncome) +
                        Number(el.revenueRealization) -
                        sumHppItemValues(el.hppItems, el.totalProduction) -
                        sumBopValues(el.bopItems)
                    )}`}
                  />
                </div>
              ))}
              {/* Month */}
              {allWeekResult.length === 4 && (
                <div className="border border-gray-900 rounded-lg divide-y divide-gray-900">
                  <OneValue
                    label={result?.date[0]}
                    value={result?.date[1]}
                    background="bg-indigo-400 rounded-t-lg font-bold"
                  />
                  <OneValue
                    label="PRODUKSI (KG)"
                    value={currency(result?.totalProduction)}
                  />
                  <OneValue
                    label="Volume Produksi (Kg)"
                    value={currency(
                      sumTempehValues(result?.productionItems)?.toFixed(0)
                    )}
                    className="text-xs"
                  />
                  <MultipleValue
                    label="PRODUKSI (BTG)"
                    values={result?.productionItems}
                  />
                  <OneValue
                    label="%kenaikan volume kedelai"
                    value={`${uptrendSoybeanVolum(
                      result?.productionItems,
                      result?.totalProduction
                    ).toFixed(0)}%`}
                    className="text-xs"
                  />
                  <MultipleValue
                    label="TERSIMPAN SEBELUMNYA"
                    values={result?.previouslySavedItems}
                  />
                  <OneValue label="PENJUALAN" value="" />
                  <MultipleValue label="Terjual" values={result?.soldItems} />
                  <MultipleValue
                    label="Piutang"
                    values={result?.receivableItems}
                  />
                  <MultipleValue label="BS" values={result?.returnItems} />
                  <MultipleValue label="Afkir" values={result?.writeOffItems} />
                  <OneValue
                    label="Pendapatan Lain"
                    value={
                      result?.otherIncome
                        ? `Rp${currency(result?.otherIncome)}`
                        : "-"
                    }
                    className="text-xs"
                  />
                  <OneValue
                    label="Realisasi Pendapatan"
                    value={`Rp${currency(result?.revenueRealization)}`}
                    className="text-xs"
                  />
                  <OneValue
                    label="Pendapatan Total"
                    value={`Rp${currency(
                      Number(result?.otherIncome) +
                        Number(result?.revenueRealization)
                    )}`}
                  />
                  <OneValue label="HPP" value="" />
                  <MultipleValue values={result?.hppItems} />
                  <OneValue
                    label="TOTAL HPP"
                    value={`Rp${currency(result?.totalHpp)}`}
                  />
                  <OneValue
                    label="LABA KOTOR"
                    value={`Rp${currency(result?.totalGrossProfit)}`}
                  />
                  <OneValue label="BIAYA OPERASIONAL" value="" />
                  <MultipleValue values={result?.bopItems} />
                  <OneValue
                    label="JUMLAH BOP"
                    value={`Rp${currency(sumBopValues(result?.bopItems))}`}
                  />
                  <OneValue
                    label="LABA BERSIH"
                    value={`Rp${currency(
                      Number(result?.otherIncome) +
                        Number(result?.revenueRealization) -
                        result?.totalHpp -
                        sumBopValues(result?.bopItems)
                    )}`}
                  />
                </div>
              )}
              {/* End of detail section */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Recap;
