import { DocumentSnapshot } from "./TFirebase";

export type TPlan = {
  id?: string;
  BranchId: string;
  date: number;
  totalProduction: string;
  totalProfit: string;
  createdAt: number;
  updatedAt: number;
};

export const tPlanConverter = {
  fromFirestore: (snapshot: DocumentSnapshot): TPlan => {
    return { ...snapshot.data(), id: snapshot.id } as unknown as TPlan;
  },
  toFirestore: (plan: TPlan) => {
    return plan;
  },
};
