import React, { Fragment } from "react";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";

import NumberInput from "../../components/NumberInput";
import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";
import Notification from "../../components/Notification";
import Button from "../../components/Button";

import services from "../../services";

import { useCustomContext } from "../../hooks/useCustomContext";

import { TBopItem } from "../../types/TBopItem";
import { TBranch } from "../../types/TBranch";
import { TItem } from "../../types/TItem";
import { TReport } from "../../types/TReport";

type TValue = {
  id: any;
  label: string;
  value: any;
  other?: any;
  defaultValue?: any;
};

const ReportForm = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const context = useCustomContext();

  const id = search.get("id");
  const mode = search.get("mode");
  const month = search.get("month");
  const branch = search.get("branch");

  const isEdit = mode === "edit";

  const [isError, setIsError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [show, setShow] = React.useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] =
    React.useState<string>("");

  const [branches, setBranches] = React.useState<TBranch[]>([]);

  const [date, setDate] = React.useState<string>(moment(new Date()).format("YYYY-MM-DD")); // prettier-ignore
  const [branchId, setBranchId] = React.useState<string>("");
  const [totalProduction, setTotalProduction] = React.useState<string>("");
  const [otherIncome, setOtherIncome] = React.useState<string>("");
  const [revenueRealization, setRevenueRealization] =
    React.useState<string>("");
  const [productionItems, setProductionItems] = React.useState<TValue[]>([]);
  const [previouslySavedItems, setPreviouslySavedItems] = React.useState<TValue[]>([]); // prettier-ignore
  const [soldItems, setSoldItems] = React.useState<TValue[]>([]);
  const [receivableItems, setReceivableItems] = React.useState<TValue[]>([]);
  const [returnItems, setReturnItems] = React.useState<TValue[]>([]);
  const [writeOffItems, setWriteOffItems] = React.useState<TValue[]>([]);
  const [soybean, setSoybean] = React.useState<string>("");
  const [plastic, setPlastic] = React.useState<string>("");
  const [gas, setGas] = React.useState<string>("");
  const [firewood, setFirewood] = React.useState<string>("");
  const [yeast, setYeast] = React.useState<string>("");
  const [other, setOther] = React.useState<string>("");
  const [bopItems, setBopItems] = React.useState<TValue[]>([]);

  const getAllBranches = async () => {
    const data = await services.branch.getBranches();

    if (data.ok === true) {
      setBranches(data.data);
    }
  };

  const generateEditItems = (values: any[]) => {
    const result = values.map((el) => ({
      id: el.id,
      label: el.name,
      value: el.value || "",
      other: el.other || "",
    }));

    return result;
  };

  const getReport = async (id: string) => {
    const data = await services.report.getReport(id);

    if (data.ok) {
      const report = data.data;

      setDate(moment.unix(report.date).format("YYYY-MM-DD"));
      setBranchId(report.BranchId);
      setTotalProduction(report.totalProduction);
      setOtherIncome(report.otherIncome);
      setRevenueRealization(report.revenueRealization);
      setSoybean(report.hppItems[1].value);
      setPlastic(report.hppItems[2].value);
      setGas(report.hppItems[3].value);
      setFirewood(report.hppItems[4].value);
      setYeast(report.hppItems[5].value);
      setOther(report.hppItems[6].value);
      setBopItems(generateEditItems(report.bopItems));

      setProductionItems(generateEditItems(report.productionItems));
      setPreviouslySavedItems(generateEditItems(report.previouslySavedItems));
      setSoldItems(generateEditItems(report.soldItems));
      setReceivableItems(generateEditItems(report.receivableItems));
      setReturnItems(generateEditItems(report.returnItems));
      setWriteOffItems(generateEditItems(report.writeOffItems));
    }
  };

  React.useEffect(() => {
    if (id && isEdit) {
      getReport(id);
    }
  }, [id, isEdit]);

  React.useEffect(() => {
    getAllBranches();
  }, []);

  const getAllBopAndItems = async (id: string) => {
    const items = await services.item.getItemsByBranchId(id);
    const bopItems = await services.bopItem.getBopItemsByBranchId(id);

    if (bopItems.ok) {
      const bopItemsValue: TValue[] = bopItems.data.map((el: TBopItem) => ({
        id: el.id!,
        label: el.name,
        value: el.defaultAmount,
      }));

      setBopItems(bopItemsValue);
    }

    if (items.ok) {
      const itemsValue: TValue[] = items.data.map((el: TItem) => ({
        id: el.id!,
        label: el.name,
        value: "",
      }));

      setProductionItems(itemsValue);
      setPreviouslySavedItems(itemsValue);
      setSoldItems(itemsValue);
      setReceivableItems(itemsValue);
      setReturnItems(itemsValue);
      setWriteOffItems(itemsValue);
    }
  };

  const branchOptions = branches.map((el) => ({ id: el.id, label: el.name }));

  const onSelectBranch = (value: string) => {
    setBranchId(value);
    getAllBopAndItems(value);
  };

  const onChangeValue = (
    values: TValue[],
    setValues: Function,
    id: string,
    value: any
  ) => {
    const updatedValues = values.map((val) => {
      if (val.id === id) {
        return {
          ...val,
          value,
        };
      }

      return val;
    });

    setValues(updatedValues);
  };

  const onChangeWeightValue = (
    values: TValue[],
    setValues: Function,
    id: string,
    value: any
  ) => {
    const updatedValues = values.map((val) => {
      if (val.id === id) {
        return {
          ...val,
          other: value,
        };
      }

      return val;
    });

    setValues(updatedValues);
  };

  const onClearState = () => {
    setDate("");
    setBranchId("");
    setTotalProduction("");
    setOtherIncome("");
    setRevenueRealization("");
  };

  const validate = () => {
    if (date && branchId && totalProduction) {
      return true;
    } else {
      setIsError(true);
      return false;
    }
  };

  const generateItems = (values: TValue[]) => {
    const globalItems = context.items;

    const result = values.map((el) => ({
      id: el.id,
      name: el.label,
      value: el.value || 0,
      other:
        el.other ||
        globalItems.find(
          (item) => item.name === el.label && item.BranchId === branchId
        )?.weight ||
        null,
    }));

    return result;
  };

  const onSubmit = async () => {
    const payload: TReport = {
      date: moment(date).unix(),
      BranchId: branchId,
      totalProduction,
      productionItems: generateItems(productionItems),
      previouslySavedItems: generateItems(previouslySavedItems),
      soldItems: generateItems(soldItems),
      receivableItems: generateItems(receivableItems),
      returnItems: generateItems(returnItems),
      writeOffItems: generateItems(writeOffItems),
      otherIncome,
      revenueRealization,
      hppItems: [
        {
          id: 0,
          name: "Biaya Kedelai",
          value: "",
        },
        {
          id: 1,
          name: "Harga Kedelai Rp/Kg",
          value: soybean,
        },
        {
          id: 2,
          name: "Plastik",
          value: plastic,
        },
        {
          id: 3,
          name: "Gas",
          value: gas,
        },
        {
          id: 4,
          name: "Kayu Bakar (boiler)",
          value: firewood,
        },
        {
          id: 5,
          name: "Ragi + Asoy",
          value: yeast,
        },
        {
          id: 6,
          name: "Other",
          value: other,
        },
      ],
      bopItems: generateItems(bopItems),
      createdAt: moment().unix(),
      updatedAt: moment().unix(),
    };

    let report;
    if (isEdit) {
      report = await services.report.updateReport(id!, payload);
    } else {
      report = await services.report.createReport(payload);
    }

    if (report.ok) {
      if (isEdit) {
        setNotificationMessage("Berhasil memperbarui laporan");
      } else {
        setNotificationMessage("Berhasil membuat laporan");
      }
      setShow(true);
      setLoading(false);
      onClearState();
      navigate(`/report?month=${month}&branch=${branch}`);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <Notification show={show} setShow={setShow} title={notificationMessage} />
      <TextInput
        type="date"
        id="date"
        label="Tanggal"
        value={date}
        setValue={setDate}
        isError={isError}
        errorMessage="Tanggal wajib diisi"
        required={true}
      />
      <div className="mt-3">
        <SelectInput
          id="branchId"
          label="Cabang"
          value={branchId}
          setValue={onSelectBranch}
          values={branchOptions}
          placeholder="Pilih cabang"
          isError={isError}
          errorMessage="Cabang wajib diisi"
          required={true}
        />
      </div>
      {branchId && (
        <>
          <div className="mt-3">
            <NumberInput
              id="totalProduction"
              label="Jumlah Produksi (Kg)"
              value={totalProduction}
              setValue={setTotalProduction}
              isError={isError}
              errorMessage="Data wajib diisi"
              required={true}
            />
          </div>
          <div className="sm:flex sm:flex-row sm:justify-between grid grid-cols-2 gap-3">
            <div>
              <label
                htmlFor="productionItems"
                className="mt-3 block text-sm font-medium text-gray-700"
              >
                Produksi
              </label>
              {productionItems.map((el) => (
                <Fragment key={el.id}>
                  <div className="flex">
                    <div className="bg-white border-green-400 rounded-l-md px-3 mt-1 w-1/2 sm:w-20 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                      {el.label}
                    </div>
                    <div className="w-1/2 sm:w-20">
                      <NumberInput
                        id={el.id}
                        value={el.value}
                        setValue={(value: any) =>
                          onChangeValue(
                            productionItems,
                            setProductionItems,
                            el.id,
                            value
                          )
                        }
                        isError={isError}
                        errorMessage="Data wajib diisi"
                        required={true}
                        rounded="rounded-r-md border-green-400"
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="bg-white border-yellow-400 rounded-l-md px-3 mt-1 w-1/2 sm:w-20 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                      Berat
                    </div>
                    <div className="w-1/2 sm:w-20">
                      <NumberInput
                        id={el.id}
                        value={el.other}
                        setValue={(value: any) =>
                          onChangeWeightValue(
                            productionItems,
                            setProductionItems,
                            el.id,
                            value
                          )
                        }
                        isError={isError}
                        errorMessage="Data wajib diisi"
                        required={true}
                        rounded="rounded-r-md border-yellow-400"
                      />
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>

            <div>
              <label
                htmlFor="previouslySavedItems"
                className="mt-3 block text-sm font-medium text-gray-700"
              >
                Tersimpan Sebelumnya
              </label>
              {previouslySavedItems.map((el) => (
                <div key={el.id} className="flex">
                  <div className="bg-white rounded-l-md px-3 mt-1 w-1/2 sm:w-20 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                    {el.label}
                  </div>
                  <div className="w-1/2 sm:w-20">
                    <NumberInput
                      id={el.id}
                      value={el.value}
                      setValue={(value: any) =>
                        onChangeValue(
                          previouslySavedItems,
                          setPreviouslySavedItems,
                          el.id,
                          value
                        )
                      }
                      isError={isError}
                      errorMessage="Data wajib diisi"
                      required={true}
                      rounded="rounded-r-md"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div>
              <label
                htmlFor="soldItems"
                className="mt-3 block text-sm font-medium text-gray-700"
              >
                Terjual
              </label>
              {soldItems.map((el) => (
                <div key={el.id} className="flex">
                  <div className="bg-white rounded-l-md px-3 mt-1 w-1/2 sm:w-20 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                    {el.label}
                  </div>
                  <div className="w-1/2 sm:w-20">
                    <NumberInput
                      id={el.id}
                      value={el.value}
                      setValue={(value: any) =>
                        onChangeValue(soldItems, setSoldItems, el.id, value)
                      }
                      isError={isError}
                      errorMessage="Data wajib diisi"
                      required={true}
                      rounded="rounded-r-md"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div>
              <label
                htmlFor="receivableItems"
                className="mt-3 block text-sm font-medium text-gray-700"
              >
                Piutang
              </label>
              {receivableItems.map((el) => (
                <div key={el.id} className="flex">
                  <div className="bg-white rounded-l-md px-3 mt-1 w-1/2 sm:w-20 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                    {el.label}
                  </div>
                  <div className="w-1/2 sm:w-20">
                    <NumberInput
                      id={el.id}
                      value={el.value}
                      setValue={(value: any) =>
                        onChangeValue(
                          receivableItems,
                          setReceivableItems,
                          el.id,
                          value
                        )
                      }
                      isError={isError}
                      errorMessage="Data wajib diisi"
                      required={true}
                      rounded="rounded-r-md"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div>
              <label
                htmlFor="returnItems"
                className="mt-3 block text-sm font-medium text-gray-700"
              >
                BS
              </label>
              {returnItems.map((el) => (
                <div key={el.id} className="flex">
                  <div className="bg-white rounded-l-md px-3 mt-1 w-1/2 sm:w-20 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                    {el.label}
                  </div>
                  <div className="w-1/2 sm:w-20">
                    <NumberInput
                      id={el.id}
                      value={el.value}
                      setValue={(value: any) =>
                        onChangeValue(returnItems, setReturnItems, el.id, value)
                      }
                      isError={isError}
                      errorMessage="Data wajib diisi"
                      required={true}
                      rounded="rounded-r-md"
                    />
                  </div>
                </div>
              ))}
            </div>

            <div>
              <label
                htmlFor="writeOffItems"
                className="mt-3 block text-sm font-medium text-gray-700"
              >
                Afkir
              </label>
              {writeOffItems.map((el) => (
                <div key={el.id} className="flex">
                  <div className="bg-white rounded-l-md px-3 mt-1 w-1/2 sm:w-20 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                    {el.label}
                  </div>
                  <div className="w-1/2 sm:w-20">
                    <NumberInput
                      id={el.id}
                      value={el.value}
                      setValue={(value: any) =>
                        onChangeValue(
                          writeOffItems,
                          setWriteOffItems,
                          el.id,
                          value
                        )
                      }
                      isError={isError}
                      errorMessage="Data wajib diisi"
                      required={true}
                      rounded="rounded-r-md"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-3">
            <NumberInput
              id="otherIncome"
              label="Pemasukan Lain"
              value={otherIncome}
              setValue={setOtherIncome}
            />
          </div>

          <div className="mt-3">
            <NumberInput
              id="revenueRealization"
              label="Realisasi Pendapatan"
              value={revenueRealization}
              setValue={setRevenueRealization}
              isError={isError}
              errorMessage="Data wajib diisi"
              required={true}
            />
          </div>

          <div>
            <label
              htmlFor="plasticItems"
              className="mt-3 block text-sm font-medium text-gray-700"
            >
              HPP
            </label>

            <div className="flex">
              <div className="bg-white rounded-l-md px-3 mt-1 w-full sm:w-40 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                Harga Kedelai
              </div>
              <div className="w-full">
                <NumberInput
                  id="soybean"
                  value={soybean}
                  setValue={setSoybean}
                  isError={isError}
                  errorMessage="Data wajib diisi"
                  required={true}
                  rounded="rounded-r-md"
                />
              </div>
            </div>

            <div className="flex">
              <div className="bg-white rounded-l-md px-3 mt-1 w-full sm:w-40 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                Plastik Tempe
              </div>
              <div className="w-full">
                <NumberInput
                  id="plastic"
                  value={plastic}
                  setValue={setPlastic}
                  rounded="rounded-r-md"
                />
              </div>
            </div>

            <div className="flex">
              <div className="bg-white rounded-l-md px-3 mt-1 w-full sm:w-40 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                Gas
              </div>
              <div className="w-full">
                <NumberInput
                  id="gas"
                  value={gas}
                  setValue={setGas}
                  rounded="rounded-r-md"
                />
              </div>
            </div>

            <div className="flex">
              <div className="bg-white rounded-l-md px-3 mt-1 w-full sm:w-40 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                Kayu Bakar
              </div>
              <div className="w-full">
                <NumberInput
                  id="firewood"
                  value={firewood}
                  setValue={setFirewood}
                  rounded="rounded-r-md"
                />
              </div>
            </div>

            <div className="flex">
              <div className="bg-white rounded-l-md px-3 mt-1 w-full sm:w-40 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                Ragi + Asoy
              </div>
              <div className="w-full">
                <NumberInput
                  id="yeast"
                  value={yeast}
                  setValue={setYeast}
                  rounded="rounded-r-md"
                />
              </div>
            </div>

            <div className="flex">
              <div className="bg-white rounded-l-md px-3 mt-1 w-full sm:w-40 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                Lain-lain
              </div>
              <div className="w-full">
                <NumberInput
                  id="other"
                  value={other}
                  setValue={setOther}
                  rounded="rounded-r-md"
                />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="plasticItems"
              className="mt-3 block text-sm font-medium text-gray-700"
            >
              BOP
            </label>
            {bopItems.map((el) => (
              <div key={el.id} className="flex">
                <div className="bg-white rounded-l-md px-3 mt-1 w-full sm:w-40 flex text-sm font-medium text-gray-700 border-y border-l items-center">
                  {el.label}
                </div>
                <div className="w-full">
                  <NumberInput
                    id={el.id}
                    value={el.value}
                    setValue={(value: any) =>
                      onChangeValue(bopItems, setBopItems, el.id, value)
                    }
                    isError={isError}
                    errorMessage="Data wajib diisi"
                    required={true}
                    rounded="rounded-r-md"
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="mt-3 flex gap-x-2 justify-end">
        <button
          onClick={() => navigate(`/report?month=${month}&branch=${branch}`)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Batal
        </button>
        <Button label={isEdit ? "Edit" : "Submit"} onClick={onSubmit} />
      </div>
    </div>
  );
};

export default ReportForm;
