import { DocumentSnapshot } from "./TFirebase";

export type TBopItem = {
  id?: string;
  name: string;
  BranchId: string;
  isDepositComponent: boolean;
  isEducationBudget: boolean;
  number: number;
  defaultAmount: string;
  description: string;
  createdAt: number;
  updatedAt: number;
};

export const tBopItemConverter = {
  fromFirestore: (snapshot: DocumentSnapshot): TBopItem => {
    return { ...snapshot.data(), id: snapshot.id } as unknown as TBopItem;
  },
  toFirestore: (bopItem: TBopItem) => {
    return bopItem;
  },
};
