import moment from "moment";
import { FC, useState } from "react";

import services from "../services";

import { TBranch } from "../types/TBranch";
import { TReport } from "../types/TReport";
import { currency } from "../utils/currency";

import ModalConfirmDelete from "./ModalConfirmDelete";

interface props {
  data: TReport[];
  setShow: Function;
  setNotificationTitle: Function;
  refetchData: Function;
  onEdit: Function;
  branches: TBranch[];
  branchId: string;
  month: string;
}

const ReportTable: FC<props> = ({
  data,
  setShow,
  setNotificationTitle,
  refetchData,
  onEdit,
  branches,
  branchId,
  month,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  const onDelete = async () => {
    const remove = await services.report.deleteReport(id);

    if (remove.ok) {
      setShow(true);
      setNotificationTitle(remove.message);
      refetchData(branchId, month);
    }
  };

  const onConfirmDelete = (id: string) => {
    setId(id);
    setOpen(true);
  };

  return (
    <>
      <ModalConfirmDelete open={open} setOpen={setOpen} onDelete={onDelete} />
      <table className="min-w-full divide-y divide-gray-200 border">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Cabang
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Jumlah Produksi (Kg)
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Harga Kedelai Rp/Kg
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Tanggal
            </th>
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((el: any) => (
            <tr key={el.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">
                  {branches.find((branch) => branch.id === el.BranchId)?.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {currency(el.totalProduction)}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {currency(
                    el?.hppItems?.find(
                      (el: any) => el.name === "Harga Kedelai Rp/Kg"
                    )?.value
                  )}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {moment.unix(el.date).format("DD MMMM YYYY")}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <div className="inline-flex">
                  <div
                    onClick={() => onEdit(el.id)}
                    className="cursor-pointer text-indigo-600 hover:text-indigo-900"
                  >
                    Edit
                  </div>
                  <div
                    onClick={() => onConfirmDelete(el?.id!)}
                    className="cursor-pointer ml-2 text-red-600 hover:text-red-900"
                  >
                    Delete
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default ReportTable;
