export const success = (message: string, data: any) => {
  return {
    ok: true,
    message,
    data,
  };
};

export const fail = (message?: string) => {
  return {
    ok: false,
    message: message || "Oops, Something went wrong",
    data: null,
  };
};

export const notFound = (message?: string) => {
  return {
    ok: false,
    message: message || "Data tidak ditemukan",
    data: null,
  };
};
